import React from "react";
import { SortablePane, Pane } from "react-sortable-pane";
import CreatableSelect from "react-select/creatable";
import FGEditor from "../../../../../common/components/sequences/FGEditor";
import { AutosizeInput } from "../../../../../common/components/generic/AutosizeInput";

class SortableTimeItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taglist_open: false,
      pane_move: false,
      dragged_elem: null,
      is_sortable: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnResize = this.handleOnResize.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.updatePanes = this.updatePanes.bind(this);
    this.handleSortable = this.handleSortable.bind(this);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
  }

  handleSortable(state) {
    this.setState({
      is_sortable: state,
    });
  }

  handleSort() {}

  handleDragStart(e, id) {
    this.setState({
      is_sortable: true,
    });

    this.setState({
      pane_move: true,
      dragged_elem: Number(id),
    });
  }

  handleDragStop(e, id, elem, order) {
    if (this.state.pane_move) {
      const old_items = this.props.timeslots;
      const new_list = [];

      order.forEach((item) => {
        old_items.forEach((l) => {
          if (Number(l.id) === Number(item)) {
            new_list.push(l);
          }
        });
      });
      this.props.handleOnReOrder(new_list);
      this.setState({
        pane_move: false,
        dragged_elem: null,
      });
    }
  }

  handleOnResize(e, key, dir, ref, data, timeSlotDurationInMinutes) {
    let duration =
      (data.height / this.props.slotHeight) * timeSlotDurationInMinutes;

    let index = 0;
    const { timeslots: items } = this.props;

    let old_duration;
    items.forEach((l, i) => {
      if (Number(l.id) === Number(key)) {
        index = i;

        // old height
        old_duration = l.duration;
      }
    });

    old_duration =
      (old_duration / timeSlotDurationInMinutes) * this.props.slotHeight;

    duration = old_duration + data.height;
    duration = (duration / this.props.slotHeight) * timeSlotDurationInMinutes;

    this.props.handleOnResize(Number(index), duration);
  }

  handleInputChange(id, type, event) {
    if (type === "text") {
      this.props.handleInputChange(id, type, event);
    } else {
      this.props.handleInputChange(id, type, event.target.value);
    }
  }

  handleDeleteClick(e, id) {
    e.preventDefault();
    this.props.handleDeleteClick(id);
  }

  handleMaterialChange(nr, tags) {
    this.props.update_material(nr, tags);
  }

  onMenuOpen() {
    this.setState({
      taglist_open: true,
    });
  }

  onMenuClose() {
    this.setState({
      taglist_open: false,
    });
  }

  getMaterialTags(i) {
    const timeslot = this.props.timeslots[i];
    return Array.isArray(timeslot.material) ? timeslot.material : [];
  }

  updatePanes() {
    this.handleSortable(true);
    this.props.updateItems();
  }

  render() {
    const { dragged_elem, is_sortable } = this.state;
    const { timeslotDurationInMinutes, timeslots, addNewPane } = this.props;

    const edit_on = this.state.taglist_open ? " on " : "close";
    const pane_move_class = !this.state.pane_move ? " no_move " : "";

    // artikulations taktung 100 = 5 min (20px = 1 minute)
    let taktung = 100;
    if (this.props.settings) {
      if (this.props.settings.settings.taktung) {
        taktung = parseInt(this.props.settings.settings.taktung);
      }
    }

    const panekey = `${this.props.panekey}-pane-${timeslots.length}`;

    return (
      <SortablePane
        className="timeslot-item-wrapper"
        direction="vertical"
        margin={0}
        onResizeStop={(e, key, dir, ref, d) => {
          this.handleOnResize(e, key, dir, ref, d, timeslotDurationInMinutes);
        }}
        onDragStop={(e, id, elem, order) => {
          this.handleDragStop(e, id, elem, order);
        }}
        onDragStart={(e, id, elem, order) => {
          this.handleDragStart(e, id, elem, order);
        }}
        onOrderChange={(oldPanes, newPanes) => {
          this.handleSort(oldPanes, newPanes);
        }}
        dragHandleClassName="mover"
        disableEffect={false}
        isSortable={is_sortable}
        key={panekey}
      >
        {timeslots.map((tslot, i) => {
          const tslotkey = tslot.id;
          const height =
            (tslot.duration / timeslotDurationInMinutes) *
            this.props.slotHeight;
          const index = Number(i);
          const autofocus = Boolean(addNewPane) && i === timeslots.length - 1;
          const text = tslot.text ? tslot.text : "";
          const drag_active_css =
            dragged_elem && dragged_elem === Number(tslot.id)
              ? " drag_active"
              : "";

          return (
            <Pane
              className={`pane-item ${pane_move_class} ${drag_active_css}`}
              id={tslot.id}
              key={tslotkey}
              style={{ height }}
              size={{ width: "100%", height }}
              resizable={{ x: false, y: true, xy: false }}
              grid={[1, taktung]}
            >
              <div className="timeline-slot">
                <div className="header-edit">
                  <a
                    href="#"
                    onClick={(e) => {
                      this.handleDeleteClick(e, i);
                    }}
                  >
                    löschen
                  </a>
                </div>

                <div className="slot-wrapper">
                  <div className={`editor-wrapper ${edit_on}`}>
                    <div className="material-edit">
                      <div className="header-title">Material</div>
                    </div>

                    <CreatableSelect
                      openOnFocus
                      isMulti
                      onChange={(e) => {
                        this.handleMaterialChange(i, e);
                      }}
                      options={this.props.material_preselect}
                      placeholder="Hier Material eintragen"
                      className="cleanSelect"
                      classNamePrefix="react-select"
                      onMenuOpen={this.onMenuOpen}
                      onMenuClose={this.onMenuClose}
                      value={this.getMaterialTags(i)}
                    />
                  </div>

                  <div
                    className="mover"
                    onMouseOver={() => {
                      this.handleSortable(true);
                    }}
                  >
                    <span className="dragger">
                      <img
                        src="/assets/images/dragger.png"
                        className="dragger"
                      />
                    </span>
                  </div>

                  <div className="content-edit">
                    <div className="header">
                      <AutosizeInput
                        onChange={(e) => this.handleInputChange(i, "title", e)}
                        type="text"
                        className="font-bold border-0 bg-transparent text-sm max-w-[300px]"
                        name="title"
                        value={tslot.title}
                        autoFocus={autofocus}
                        onBlur={this.updatePanes}
                        placeholder="Artikulation"
                      />
                      <span className="duration">
                        {" "}
                        | {Math.round(tslot.duration)} Minuten
                      </span>
                    </div>

                    <div className="text">
                      <FGEditor
                        key={`dayslot-edit-pane-${index}`}
                        placeholder="Hier Details eintragen"
                        value={text}
                        hideToolbar={false}
                        onChange={(e) => {
                          this.handleInputChange(i, "text", e);
                        }}
                        onBlurCall={this.updatePanes}
                        handleSortable={this.handleSortable}
                      />
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </Pane>
          );
        })}
      </SortablePane>
    );
  }
}
export default SortableTimeItems;
