export const SET_TAGLIST = "SET_TAGLIST";
/**
 * create tags for different scopes
 * @param data
 * @returns {{type: string, material_tags: Array, lehrplanbezug_tags: Array}}
 */
export function createTags(data) {
  const material_preselect = [];
  const _material_filter = [];
  const lehrplanbezug_list = [];
  const __lehrplanbezug_list = [];

  data.sequences_list.forEach((ts) => {
    ts.timeslots.forEach((elem) => {
      elem.data.forEach((item) => {
        if (item.material) {
          item.material.forEach((material) => {
            if (!_material_filter[material.value]) {
              _material_filter[material.value] = material.value;
              material_preselect.push({
                label: material.label,
                value: material.value,
              });
            }
          });
        }
      });
      if (elem.lehrplanbezug && elem.lehrplanbezug.length > 0) {
        //                        var str_lid = String(ts.sequence_item_id) + String(tt.classroom_id);
        const str_lid = String(ts.sequence_item_id);

        if (!lehrplanbezug_list[str_lid]) {
          lehrplanbezug_list[str_lid] = [];
          __lehrplanbezug_list[str_lid] = [];
        }
        elem.lehrplanbezug.forEach((lb) => {
          if (!__lehrplanbezug_list[str_lid][lb.value]) {
            __lehrplanbezug_list[str_lid][lb.value] = lb.value;
            lehrplanbezug_list[str_lid].push({
              label: lb.label,
              value: lb.value,
            });
          }
        });
      }
    });
  });

  const lp_list = [];
  const check_lp_list = [];

  if (data.sequences && data.sequences.sequence_items.length > 0) {
    data.sequences.sequence_items.forEach((elem) => {
      if (elem.data && elem.data.length > 0) {
        elem.data.forEach((d) => {
          // check die vorherige list:

          if (lehrplanbezug_list[d.id]) {
            const fid = String(elem._id);

            if (!lp_list[fid]) {
              lp_list[fid] = [];
              check_lp_list[fid] = [];
            }
            lehrplanbezug_list[d.id].forEach((ll) => {
              if (!check_lp_list[fid][ll.label]) {
                lp_list[fid].push(ll);
                check_lp_list[fid][ll.label] = [ll.label];
              }
            });
          }
        });
      }
    });
  }
  const res = {
    type: SET_TAGLIST,
    material_tags: material_preselect,
    lehrplanbezug_tags: lp_list,
  };
  return res;
}
