import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RootState } from "../../app/store";

type SliceState = {
  year: number;
  latest: number;
  region: string;
  label: string;
  is_active: boolean;
  show_created_success: boolean;
};

const initialState: SliceState = {
  year: 0,
  region: "",
  label: "",
  is_active: false,
  latest: 0,
  show_created_success: false,
};

const currentSchoolyearSlice = createSlice({
  name: "current-schoolyear",
  initialState,
  reducers: {
    setSchoolYear: (
      state,
      action: PayloadAction<{
        year: number;
        latest: number;
        region: string;
        is_active: boolean;
        label: string;
        show_created_success?: boolean;
      }>,
    ) => {
      const { year, latest, region, is_active, label, show_created_success } =
        action.payload;
      return {
        ...state,
        year,
        latest,
        region,
        is_active,
        label,
        show_created_success: Boolean(show_created_success),
      };
    },
    stampShowCreateSchoolyear(state) {
      return { ...state, show_created_success: false };
    },
  },
});

export default currentSchoolyearSlice.reducer;
export const { setSchoolYear, stampShowCreateSchoolyear } =
  currentSchoolyearSlice.actions;

// SELECTORS

export function selectCurrentSchoolyear(state: RootState) {
  return state.current_schoolyear.year;
}

export const selectCurrentSchoolyearData = createSelector(
  [(state) => state.current_schoolyear],
  (currentSchoolyear) => {
    const { year, latest } = currentSchoolyear;
    return {
      ...currentSchoolyear,
      label: `${year}/${String(year + 1).slice(2)}`,
      is_archive: year < latest,
    };
  },
);
