import React from "react";
import { createSelectable } from "react-selectable-fast";
import { classList } from "../../../../../common/utils/classList";

function TimeRow({ selectableRef, isSelected, isSelecting, minute }) {
  return (
    <div
      ref={selectableRef}
      className={classList({
        "time-row item": true,
        selecting: isSelecting,
        selected: isSelected,
      })}
      data-minute={minute}
    >
      <div className="label" />
    </div>
  );
}
export default createSelectable(TimeRow);
