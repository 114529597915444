import { combineReducers } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

import { authentication } from "../features/authentication/reducer";
import password from "../features/password/password-slice";
import { registration } from "../features/registration/reducer";

import activeView from "../features/active-view/active-view-slice";
import { classes } from "../features/classes/reducer";
import { timetable } from "../features/timetable/reducer";
import { payment } from "../features/payment/reducer";
import { subjects } from "../features/subjects/reducer";
import { addSubjectForm } from "../features/add-subject-form/reducer";

import { sequence_items } from "../features/timeline/reducer";
import { sequences } from "../features/sequence/reducer";
import { taglist } from "../features/taglist/reducer";

import CurrentSchoolyearReducer from "../features/current-schoolyear/current-schoolyear-slice";

import { api } from "../services/api";
import { sessionClipboard } from "../services/session-clipboard";

const reducers = {
  // new duck style
  current_schoolyear: CurrentSchoolyearReducer,

  // old reducers
  authentication,
  registration,
  payment,
  active_view: activeView,
  password,
  timetable,
  addSubjectForm,
  classes,
  subjects,
  sequence_items,
  sequences,
  taglist,
  [api.reducerPath]: api.reducer,
  [sessionClipboard.reducerPath]: sessionClipboard.reducer,
};

const combinedReducer = combineReducers(reducers);
type State = ReturnType<typeof combinedReducer>;

const rootReducer = (state: State | undefined, action: Action) => {
  // reset state if action is logout
  const newState = action.type === "LOGOUT" ? undefined : state;
  return combinedReducer(newState, action);
};

export default rootReducer;
