import React, { useEffect, useState, useRef } from "react";
import { DateTime } from "luxon";
import Dropdown, { type Option } from "react-dropdown";
import Modal from "react-modal";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { selectSchoolWithHolidays } from "../../../../features/school/reducer";
import { region_countries } from "../../../config/region_contries";
import IconDelete from "../../../../assets/images/svg/holiday_delete.svg";
import { api } from "../../../../services/api";
import type {
  HolidayItemData,
  SchoolyearData,
} from "../../../../features/holidays/types";

import { defaultSettings } from "../../../../features/settings/settings-slice";

type HolidayEditFormState = {
  itemEditMode: boolean;
  editMode: boolean;
  showModal: boolean;
  deleteHolidayItemMode: boolean;
  holidayEditItemId: string;
  holidayType: string;
  schoolYearId: string;
  holidayTitle: string;
  holidayStartDate: string;
  holidayEndDate: string;
  tplHolidayId: string;
  schoolyearStart: string;
  schoolyearEnd: string;
};

function HolidayItem(props: {
  holidays: HolidayItemData[];
  holidayType: string;
  editHandler: (
    holidayItem: HolidayItemData,
    id: string,
    holidayType: string,
  ) => void;
}) {
  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      {props.holidays.map((holiday, i) => (
        <div key={`holidayKey${i}`} className="rounded p-5 bg-gray-90">
          <span className="block font-bold">{holiday.title}</span>
          <span>
            {formatHolidayDate(holiday.start, "d-L-yyyy")}
            {holiday.type !== "public" && (
              <span>
                &nbsp;bis
                <br />
                {formatHolidayDate(holiday.end, "d-L-yyyy")}
              </span>
            )}
          </span>
          <div>
            <button
              type="button"
              className="fg-btn fg-btn-tertiary mt-6"
              onClick={() => {
                props.editHandler(holiday, holiday.id, props.holidayType);
              }}
            >
              bearbeiten
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

/**
 * foremat to e.g: Mi. 12. Aug. 2024
 * @param dateStr
 * @param format
 */
function formatHolidayDate(dateStr: string, format: string): string {
  return DateTime.fromFormat(dateStr, format).toFormat("ccc. dd. MMM yyyy");
}

function setOtherDateOnStart(dateStr: string, type: string) {
  return type === "start"
    ? DateTime.fromFormat(dateStr, "yyyy-MM-d")
        .plus({ weeks: 1 })
        .toFormat("yyyy-MM-d")
    : DateTime.fromFormat(dateStr, "yyyy-MM-d")
        .minus({
          weeks: 1,
        })
        .toFormat("yyyy-MM-d");
}

function HolidayInfoList(props: {
  startDate: string;
  endDate: string;
  holidays: HolidayItemData[];
}): React.JSX.Element {
  const formatedSchloolyear = formatHolidayDate(props.startDate, "yyyy-MM-dd");
  const formatedEndSchloolyear = formatHolidayDate(props.endDate, "yyyy-MM-dd");

  const realHolidays = props.holidays.filter((el) => el.type !== "public");
  const publicHolidays = props.holidays.filter((el) => el.type === "public");

  return (
    <div className="pt-5 text-gray-25">
      <div className="flex pl-4 pr-3 pb-5">
        <span className="font-bold w-40">Schuljahr</span>
        <span>
          {formatedSchloolyear} bis {formatedEndSchloolyear}
        </span>
      </div>
      {realHolidays.length > 0 && (
        <div className="flex pl-4 pr-3 pb-5">
          <span className="font-bold w-40">Ferien</span>
          <div>
            {realHolidays.map((holiday, index) => (
              <div key={index} className="pb-4">
                {holiday.title} | {formatHolidayDate(holiday.start, "d-L-yyyy")}{" "}
                - {formatHolidayDate(holiday.end, "d-L-yyyy")}
              </div>
            ))}
          </div>
        </div>
      )}
      {publicHolidays.length > 0 && (
        <div className="flex pl-4 pr-3 pb-5">
          <span className="font-bold w-40">Feiertage</span>
          <div>
            {publicHolidays.map((holiday, index) => (
              <div key={`public-${index}`} className="pb-4">
                {holiday.title} | {formatHolidayDate(holiday.start, "d-L-yyyy")}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export function HolidayEditForm() {
  const year = useAppSelector(selectCurrentSchoolyear);
  const schoolData = useAppSelector(selectSchoolWithHolidays);

  const { data: settings = defaultSettings } =
    api.endpoints.getSettings.useQueryState(year);

  const [upsertHolidayItem] = api.useUpsertHolidayItemMutation();
  const [upsertSchoolyear] = api.useUpsertSchoolyearMutation();
  const [deleteHolidayItem] = api.useDeleteHolidayItemMutation();
  const [upsertSchoolyearRegion] = api.useUpsertSchoolyearRegionMutation();
  const [recoverHolidays] = api.useRecoverHolidaysMutation();
  const [updateSettings] = api.useUpdateSettingsMutation();

  const [state, setState] = useState<HolidayEditFormState>({
    editMode: false,
    itemEditMode: false,
    showModal: false,
    deleteHolidayItemMode: false,
    holidayEditItemId: "",
    holidayType: "",
    schoolYearId: schoolData.schoolYear,
    holidayTitle: "",
    holidayStartDate: "",
    holidayEndDate: "",
    tplHolidayId: "",
    schoolyearStart: schoolData.startDate,
    schoolyearEnd: schoolData.endDate,
  });

  useEffect(() => {
    setState({
      ...state,
      schoolyearStart: schoolData.startDate,
      schoolyearEnd: schoolData.endDate,
      schoolYearId: schoolData.schoolYear,
    });
  }, [schoolData]);

  const scrollRef = useRef<null | HTMLDivElement>(null);
  const onCloseScrollTo = () => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.style.scrollMargin = "150px";
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const resetAbSettingsEntries = () => {
    if (
      settings.settings &&
      settings.settings.ab_weeks &&
      settings.settings.ab_weeks[year]
    ) {
      const newSettings = {
        ...settings,
        settings: {
          ...settings.settings,
          ab_weeks: {
            ...settings.settings.ab_weeks,
            [year]: {
              ...settings.settings.ab_weeks[year],
              entries: [],
            },
          },
        },
      };
      updateSettings({ ...newSettings, schoolyear: year });
    }
  };

  const setItemSaveMode = (title: string, start: string) =>
    title !== "" && start !== "";

  /**
   * fired after main save click
   */
  const updateSchoolYearData = (updateData: SchoolyearData) => {
    upsertSchoolyear(updateData);
    resetAbSettingsEntries();
    setState({
      ...state,
      showModal: false,
      itemEditMode: false,
      deleteHolidayItemMode: false,
    });
  };

  const recoverHolidaysData = (type: string) => {
    const recoverData = {
      type,
      id: state.schoolYearId,
      region: schoolData.region,
      schoolyear: year,
    };
    recoverHolidays(recoverData);
    resetAbSettingsEntries();
    setState({
      ...state,
      showModal: false,
    });
  };

  const onChangeRegion = (option: Option) => {
    const updateData = {
      regionLabel: String(option.label),
      region: option.value,
      schoolyear: year,
    };
    upsertSchoolyearRegion(updateData);
    resetAbSettingsEntries();
  };

  const onChangeSchoolyearStart = (e: React.FocusEvent<HTMLInputElement>) => {
    setState({
      ...state,
      schoolyearStart: e.target.value,
    });
  };

  const onChangeSchoolyearEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      schoolyearEnd: e.target.value,
    });
  };

  const saveSchoolyearDates = () => {
    if (
      state.schoolyearStart !== schoolData.startDate ||
      state.schoolyearEnd !== schoolData.endDate
    ) {
      const updateData = {
        id: state.schoolYearId,
        end: state.schoolyearEnd,
        start: state.schoolyearStart,
        region: schoolData.region,
        schoolyear: year,
      };
      updateSchoolYearData(updateData);
      resetAbSettingsEntries();
    }
  };

  const onChangeHolidayTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      holidayTitle: e.target.value,
      itemEditMode: setItemSaveMode(e.target.value, state.holidayStartDate),
    });
  };

  const onChangeHolidayStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      holidayStartDate: e.target.value,
      itemEditMode: setItemSaveMode(state.holidayTitle, e.target.value),
      ...(state.holidayType === "public" && {
        holidayEndDate: e.target.value,
      }),
      ...(state.holidayType !== "public" &&
        state.holidayEndDate === "" && {
          holidayEndDate: setOtherDateOnStart(e.target.value, "start"),
        }),
    });
  };

  const onChangeHolidayEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      holidayEndDate: e.target.value,
      itemEditMode: true,
      ...(state.holidayStartDate === "" && {
        holidayStartDate: setOtherDateOnStart(e.target.value, ""),
      }),
    });
  };

  const deleteUserHolidayItem = () => {
    deleteHolidayItem({
      region: schoolData.region,
      schoolyear: year,
      id: state.holidayEditItemId,
      tplHolidayId: state.tplHolidayId,
    });
    resetAbSettingsEntries();
    setState({
      ...state,
      showModal: false,
      itemEditMode: false,
      deleteHolidayItemMode: false,
      holidayEditItemId: "",
      holidayEndDate: "",
      holidayStartDate: "",
      holidayTitle: "",
      holidayType: "",
    });
  };

  const updateHolidayItem = () => {
    // updateSettings(newSettings);

    const updateData = {
      id: state.holidayEditItemId,
      end: state.holidayEndDate,
      start: state.holidayStartDate,
      title: state.holidayTitle,
      type: state.holidayType,
      schoolYearId: state.schoolYearId,
      tplHolidayId: state.tplHolidayId,
      region: schoolData.region,
      schoolyear: year,
    };
    upsertHolidayItem(updateData);
    resetAbSettingsEntries();
    setState({
      ...state,
      showModal: false,
      itemEditMode: false,
      deleteHolidayItemMode: false,
      holidayEditItemId: "",
      holidayEndDate: "",
      holidayStartDate: "",
      holidayTitle: "",
      holidayType: "",
    });
  };

  // reset state
  const closeModal = () => {
    setState({
      ...state,
      showModal: false,
      holidayEditItemId: "",
      deleteHolidayItemMode: false,
      holidayEndDate: "",
      holidayStartDate: "",
      holidayTitle: "",
      holidayType: "",
    });
  };

  const setHolidayEditMode = (
    holidayItem: HolidayItemData,
    holidayId: string,
    holidayType: string,
  ) => {
    setState({
      ...state,
      showModal: true,
      holidayType,
      holidayEditItemId: holidayId,
      holidayTitle: holidayItem.title,
      tplHolidayId: holidayItem.tpl_holiday_id ?? "", // the id can be empty if there are user created vacation times
      holidayStartDate: DateTime.fromFormat(
        holidayItem.start,
        "d-L-yyyy",
      ).toFormat("yyyy-MM-dd"),
      holidayEndDate: DateTime.fromFormat(holidayItem.end, "d-L-yyyy").toFormat(
        "yyyy-MM-dd",
      ),
    });
  };

  // reset state on abort btn call
  const resetHolidayEditMode = () => {
    setState({
      ...state,
      showModal: false,
      holidayType: "",
      holidayEditItemId: "",
      holidayTitle: "",
      tplHolidayId: "",
      holidayStartDate: "",
      holidayEndDate: "",
      deleteHolidayItemMode: false,
    });
  };

  // region dropdown

  const isModified = schoolData.modified;

  const hasModifiedPublicHolidays = schoolData.modifiedPublicHolidays;
  const hasModifiedHolidays = schoolData.modifiedHolidays;

  const textRegionChanged =
    isModified || hasModifiedPublicHolidays || hasModifiedHolidays
      ? " (angepasst)"
      : "";

  const { region } = schoolData ?? "andere";
  const regionLabel =
    region === "andere"
      ? region
      : `${schoolData.region_label}${textRegionChanged}`;

  const defaultTextEmptyField = "Bitte wählen";
  const regionsList = region_countries();
  const formatedRegionList = regionsList.map((regionObj) => {
    if (regionObj.value === region && regionObj.value !== "andere") {
      return {
        ...regionObj,
        label: regionObj.label + textRegionChanged,
      };
    }
    return regionObj;
  });

  // list holidays
  // includes filter - only for test, we dont have an holidayType selector
  const realHolidays = schoolData.holidays.filter(
    (el: HolidayItemData) => el.type === "",
  );

  const publicHolidays = schoolData.holidays.filter(
    (el: HolidayItemData) => el.type === "public",
  );

  const arrowClosed = <span className="arrow-closed" />;
  const arrowOpen = <span className="arrow-open" />;

  const startYear = DateTime.fromFormat(schoolData.startDate, "yyyy-MM-d");
  const endYear = DateTime.fromFormat(schoolData.endDate, "yyyy-MM-d");
  const schoolyearLabel =
    startYear.toFormat("yyyy") === endYear.toFormat("yyyy")
      ? startYear.toFormat("yyyy")
      : `${startYear.toFormat("yyyy")}/${endYear.toFormat("yy")}`;

  const schoolYearInfo =
    schoolData.region === "andere"
      ? schoolyearLabel
      : `${schoolyearLabel} - ${schoolData.region_label}${textRegionChanged}`;

  return (
    <div id="holiday-edit-form" className="mt-5" ref={scrollRef}>
      <form>
        <div className="bg-gray-100 rounded mx-auto mb-4 max-w-[610px] pt-3 pb-1.5">
          {state.editMode ? (
            <div className="edit px-4 pb-10">
              <Modal
                isOpen={state.showModal}
                onRequestClose={closeModal}
                ariaHideApp
                className="commonModal transparent tw-pf"
                contentLabel="Ferien"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0,0,0,.5)",
                    backdropFilter: "blur(8px)",
                    zIndex: 210,
                  },
                  content: {
                    boxShadow: "none",
                  },
                }}
              >
                <div className="commonModalContent template-modal w-auto">
                  <div className="closer">
                    <a onClick={closeModal}>
                      <img alt="close" src="/assets/images/close_modal.png" />
                    </a>
                  </div>

                  <div className="rounded bg-gray-100 w-96 p-5">
                    <h2 className="text-xl font-bold mb-1.5">
                      {state.holidayType === "public" ? (
                        <span>Feiertag</span>
                      ) : (
                        <span>Ferien</span>
                      )}
                      {state.holidayEditItemId === "" ? (
                        <span> hinzufügen</span>
                      ) : (
                        <span> bearbeiten</span>
                      )}
                    </h2>
                    <div className="mb-5">
                      <span className="block mb-1.5">Name</span>
                      <input
                        type="text"
                        className="h-11 rounded bg-gray-90 p-3 w-full border border-solid border-gray-70"
                        placeholder="Name"
                        value={state.holidayTitle}
                        onChange={onChangeHolidayTitle}
                      />
                    </div>
                    <div className="mb-5">
                      <span className="block mb-1.5">
                        {state.holidayType === "public" ? (
                          <span>Datum</span>
                        ) : (
                          <span>Erster Ferientag</span>
                        )}
                      </span>
                      <input
                        type="date"
                        value={state.holidayStartDate}
                        placeholder="TT.MM.JJJJ"
                        className="h-11 rounded bg-gray-90 p-3 w-full placeholder-gray-60 border border-solid border-gray-70"
                        onChange={onChangeHolidayStart}
                      />
                    </div>
                    {state.holidayType === "" && (
                      <div className="mb-5">
                        <span className="block mb-1.5">Letzter Ferientag</span>
                        <input
                          type="date"
                          value={state.holidayEndDate}
                          placeholder="TT.MM.JJJJ"
                          className="h-11 rounded bg-gray-90 p-3 w-full placeholder-gray-60 border border-solid border-gray-70"
                          onChange={onChangeHolidayEnd}
                        />
                      </div>
                    )}
                    {state.holidayEditItemId !== "" && (
                      <div className="border-b border-gray-70 py-5 border-t text-red">
                        <div
                          className="flex cursor-pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              deleteHolidayItemMode: true,
                            });
                          }}
                        >
                          <span className="pr-2">
                            <IconDelete className="w-[18px] h-auto" />
                          </span>
                          {state.holidayType === "public" ? (
                            <span>Feiertag</span>
                          ) : (
                            <span>Ferien</span>
                          )}
                          &nbsp;Löschen
                        </div>
                        {state.deleteHolidayItemMode && (
                          <div className="rounded bg-gray-90 mt-5 p-5 text-gray-30">
                            {state.holidayType === "public" ? (
                              <div>
                                Möchtest du diesen Feiertag wirklich löschen?
                              </div>
                            ) : (
                              <div>
                                Möchtest du diese Ferien wirklich löschen?
                              </div>
                            )}
                            <button
                              type="button"
                              className="fg-btn fg-btn-primary mt-6 mr-5 min-w-32"
                              onClick={() => {
                                resetHolidayEditMode();
                              }}
                            >
                              Abbrechen
                            </button>
                            <button
                              type="button"
                              className="fg-btn fg-btn-tertiary mt-6 min-w-32"
                              onClick={() => {
                                deleteUserHolidayItem();
                              }}
                            >
                              Löschen
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      <button
                        type="button"
                        disabled={!state.itemEditMode}
                        className="fg-btn fg-btn-primary mt-6 mr-5 min-w-[125px]"
                        onClick={() => {
                          updateHolidayItem();
                        }}
                      >
                        Speichern
                      </button>

                      <button
                        type="button"
                        className="fg-btn fg-btn-secondary mt-6 min-w-[125px]"
                        onClick={() => {
                          resetHolidayEditMode();
                        }}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>

              <div className="pb-5">
                <label>Bundesland</label>
                <Dropdown
                  options={formatedRegionList}
                  onChange={onChangeRegion}
                  value={regionLabel}
                  placeholder={defaultTextEmptyField}
                  arrowClosed={arrowClosed}
                  arrowOpen={arrowOpen}
                />
              </div>
              <div className="flex pb-5 border-b border-gray-70">
                <div className="flex-1 w-1/2 mr-2">
                  <span className="block pb-1.5">Erster Schultag</span>
                  <input
                    className="h-11 rounded bg-gray-90 p-3 w-full placeholder-gray-60 border border-solid border-gray-70"
                    type="date"
                    value={state.schoolyearStart}
                    onChange={onChangeSchoolyearStart}
                    onBlur={saveSchoolyearDates}
                  />
                </div>
                <div className="flex-1 w-1/2 ml-2">
                  <span className="block pb-1.5">Letzter Schultag</span>
                  <input
                    className="h-11 rounded bg-gray-90 p-3 w-full placeholder-gray-60 border border-solid border-gray-70"
                    type="date"
                    value={state.schoolyearEnd}
                    onChange={onChangeSchoolyearEnd}
                    onBlur={saveSchoolyearDates}
                  />
                </div>
              </div>

              <div className="pb-5 border-b border-gray-70">
                <span className="block font-bold pt-5 pb-2">Ferien</span>
                <div>
                  <HolidayItem
                    holidays={realHolidays}
                    editHandler={setHolidayEditMode}
                    holidayType=""
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="fg-btn fg-btn-secondary mt-6 mr-5 min-w-32"
                    onClick={() => {
                      setState({
                        ...state,
                        holidayType: "",
                        showModal: true,
                      });
                    }}
                  >
                    Ferien hinzufügen
                  </button>
                  <button
                    type="button"
                    disabled={!hasModifiedHolidays}
                    className="fg-btn fg-btn-tertiary-light disabled:text-gray-60 mt-6 min-w-32"
                    onClick={() => {
                      recoverHolidaysData("");
                    }}
                  >
                    Ferien zurücksetzen
                  </button>
                </div>
              </div>

              <div className="pb-5 border-b border-gray-70">
                <span className="block font-bold pt-5 pb-2">Feiertage</span>
                <div>
                  <HolidayItem
                    holidays={publicHolidays}
                    editHandler={setHolidayEditMode}
                    holidayType="public"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="fg-btn fg-btn-secondary mt-6 mr-5 min-w-32"
                    onClick={() => {
                      setState({
                        ...state,
                        showModal: true,
                        holidayType: "public",
                        holidayEditItemId: "",
                      });
                    }}
                  >
                    Feiertage hinzufügen
                  </button>
                  <button
                    type="button"
                    disabled={!hasModifiedPublicHolidays}
                    className="fg-btn fg-btn-tertiary-light disabled:text-gray-60 mt-6 min-w-32"
                    onClick={() => {
                      recoverHolidaysData("public");
                    }}
                  >
                    Feiertage zurücksetzen
                  </button>
                </div>
              </div>

              <div className="flex">
                <button
                  type="submit"
                  className="fg-btn fg-btn-primary mt-6"
                  onClick={() => {
                    setState({
                      ...state,
                      editMode: false,
                    });
                    onCloseScrollTo();
                  }}
                >
                  Bearbeiten abschließen
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex pl-4 pr-3">
                <span className="text-gray-800 text-xl leading-7">
                  {schoolYearInfo}
                </span>
                <span className="ml-auto">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setState({
                        ...state,
                        editMode: true,
                      });
                    }}
                  >
                    <img
                      src="/assets/images/edit-icon.png"
                      className="max-w-[23px]"
                    />{" "}
                  </button>
                </span>
              </div>
              <HolidayInfoList
                startDate={schoolData.startDate}
                endDate={schoolData.endDate}
                holidays={schoolData.holidays}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
