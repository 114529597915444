import React from "react";
import { TwitterPicker } from "react-color";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { uniqueId } from "../../sequence_calendar/Utils";
import Subject from "./Subject";
import Auth from "../../../services/Auth";
import { colors } from "../../../constants/colorScheme";

import * as ACTIONS from "../../../../features/subjects/actions";
import * as ADD_SUBJECT_FORM_ACTIONS from "../../../../features/add-subject-form/actions";
import * as TACTIONS from "../../../../features/timetable/actions";
import * as CACTIONS from "../../../../features/classes/actions";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";

const getDefaultColor = (subjectCount = 0) =>
  subjectCount > 0 && colors[subjectCount] ? colors[subjectCount] : colors[0];

const mapStateToProps = (state) => ({
  schoolyear: selectCurrentSchoolyear(state),
  title: state.addSubjectForm.title,
  description: state.addSubjectForm.description,
  color: state.addSubjectForm.color,
  scooldays: state.addSubjectForm.scooldays,
  subjects: state.subjects,
  classes: state.classes,
  timetable: state.timetable,
});

const mapDispatchToProps = (dispatch) => {
  const simpleActionCreators = {
    onTitleChange: ADD_SUBJECT_FORM_ACTIONS.changeTitle,
    onDescriptionChange: ADD_SUBJECT_FORM_ACTIONS.changeDescription,
    onColorChange: ADD_SUBJECT_FORM_ACTIONS.changeColor,
    updateSubjectList: ACTIONS.updateSubjectList,
    changeScooldayRowText: ADD_SUBJECT_FORM_ACTIONS.changeScooldayRowText,
    addScooldayRow: ADD_SUBJECT_FORM_ACTIONS.addScooldayRow,
    fetchSubjectsList: ACTIONS.fetchSubjectsList,
    upsertTimetable: TACTIONS.upsertTimetable,
    updateClassList: CACTIONS.updateClassListItem,
  };

  return {
    ...bindActionCreators(simpleActionCreators, dispatch),
    onSubjectListAdd: (subject) => {
      dispatch(ACTIONS.addSubjectList(subject));
      dispatch(ADD_SUBJECT_FORM_ACTIONS.clearForm());
    },
  };
};

class AddSubjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      displayColorPicker: false,
      schoolyear: props.schoolyear,
    };
    this.addSubject = this.addSubject.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.colorChange = this.colorChange.bind(this);
    this.pickerShowHide = this.pickerShowHide.bind(this);
    this.handlePickerClose = this.handlePickerClose.bind(this);
    this.updateSubject = this.updateSubject.bind(this);
  }

  componentDidMount() {
    if (this.props.fetchData) {
      this.props.fetchSubjectsList(Auth.getUserId(), this.props.schoolyear);
    }
  }

  handlePickerClose(e) {
    // console.log();
    e.preventDefault();
    this.setState({ displayColorPicker: false });
  }

  /**
   * add subject to db or to local storage for registration
   */
  addSubject(e) {
    e.preventDefault();
    const { title, subjects, color } = this.props;

    const selectedColor = color || getDefaultColor(subjects.raw?.length);

    const subject = {
      title,
      color: selectedColor,
      schooldays: [],
      user_id: Auth.getUserId(),
      schoolyear: this.state.schoolyear,
    };

    this.setState((prevState) => ({
      subjects: [...prevState.subjects, subject],
    }));
    this.props.onSubjectListAdd(subject);
  }

  /**
   * update an item inside list from child subject
   * on update check if timetable elements with subject exists -> update this too
   */
  updateSubject(item) {
    // check for timetable updates
    let tt_update = false;
    if (this.props.timetable.entries) {
      this.props.timetable.entries.forEach((elem) => {
        if (elem.class.subject && elem.class.subject._id === item.id) {
          tt_update = true;
          // console.log('exists entries inside timetable -> change it');
          elem.class.subject.title = item.title;
          elem.class.subject.color = item.color;
        }
      });
    }

    // check for classlists updates
    const found_classes = [];
    if (this.props.classes) {
      this.props.classes.forEach((elem) => {
        elem.subjects.forEach((sub) => {
          if (sub._id === item.id) {
            sub.title = item.title;
            sub.color = item.color;
            // console.log('classes found to change color and ttle');
            // console.log(elem);
            found_classes.push(elem);
          }
        });
      });
    }

    item.user_id = Auth.getUserId();
    item.schoolyear = this.state.schoolyear;
    this.props.updateSubjectList(item);
    if (tt_update) {
      this.props.upsertTimetable(this.props.timetable, this.state.schoolyear);
    }
    if (found_classes.length > 0) {
      found_classes.forEach((elem) => {
        elem.schoolyear = this.state.schoolyear;
        elem.user_id = Auth.getUserId();
        // console.log('----------------------> update class llist with data: ------------------>');
        // console.log(elem);
        this.props.updateClassList(elem, this.state.schoolyear);
      });
    }
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.nextSection(this.props.subjects.filtered);
  }

  prevSection() {
    this.props.prevSection();
  }

  colorChange(color) {
    this.props.onColorChange(color.hex);
  }

  pickerShowHide(e) {
    // console.log('close/show picker');
    e.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  render() {
    const subject_list = this.props.subjects.raw;

    const enabled = this.props.title.length > 0;
    const nextenabled = subject_list.length > 0;

    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "20px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    let user_color = this.props.color;

    if (
      user_color === "" &&
      this.props.subjects.raw &&
      this.props.subjects.raw.length > 0
    ) {
      const { length } = this.props.subjects.raw;
      if (colors[length]) {
        user_color = colors[length];
      }
    } else if (user_color === "") {
      [user_color] = colors;
    }

    return (
      <div id="subjects" className="">
        <div className="subject_list">
          {subject_list.map((p, i) => (
            <Subject
              {...p}
              key={uniqueId()}
              nr={i}
              storage={this.props.storage}
              updateSubject={this.updateSubject}
              classes={this.props.classes}
              timetable={this.props.timetable}
              pre_colors={colors}
            />
          ))}
        </div>

        <div className="row">
          <div className="subject_item_container">
            <div className="fields">
              <div className="field_input">
                <label>Fach (Name)</label>
                <input
                  id="test-this"
                  className="inputfield"
                  placeholder="z.B. Mathematik"
                  value={this.props.title}
                  onChange={(event) =>
                    this.props.onTitleChange(event.target.value)
                  }
                  onKeyDown={(e) => {
                    if (enabled && e.key === "Enter") {
                      this.addSubject(e);
                      e.target.blur();
                    }
                  }}
                />
              </div>
              <div className="file_color">
                <label>Farbe</label>
                <div
                  className="color_wrapper"
                  style={{ backgroundColor: user_color }}
                  onClick={(e) => {
                    this.pickerShowHide(e);
                  }}
                >
                  <div className="triangle_color_wrapper">
                    <span className="triangle_white_down" />
                  </div>
                </div>
              </div>
              <div style={{ clear: "both" }} />
            </div>

            {this.state.displayColorPicker ? (
              <div style={popover}>
                <div
                  style={cover}
                  onClick={(e) => {
                    this.handlePickerClose(e);
                  }}
                />
                <div className="picker">
                  <TwitterPicker
                    key="main-pciker"
                    colors={colors}
                    color={user_color}
                    onChange={this.colorChange}
                  />
                </div>
              </div>
            ) : null}

            <div className="btn_wrapper">
              <button
                disabled={!enabled}
                className="btn btn-dark"
                onClick={(e) => {
                  this.addSubject(e);
                }}
              >
                anlegen
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="next">
              <button
                disabled={!nextenabled}
                className="btn btn-step"
                onClick={() => this.nextSection()}
              >
                Weiter zu Klassen
              </button>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSubjectForm);
