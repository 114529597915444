import { DateTime } from "luxon";
import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import { selectLessonDuration } from "../settings/settings-slice";
import { selectSchoolWithHolidays } from "../school/reducer";

export const ACTIVE_SEQUENCE = "ACTIVE_SEQUENCE";
export const ACTIVE_SEQUENCE_ERROR_PATCH = "ACTIVE_SEQUENCE_ERROR_PATCH";
export const SET_SLOTDAYS = "SET_SLOTDAYS";
export const SET_DAYSLOT_ID = "SET_DAYSLOT_ID";
export const ADD_PANE_TO_SLOT = "ADD_PANE_TO_SLOT";
export const CHANGE_PANE_TEXT = "CHANGE_PANE_TEXT";
export const UPDATE_SEQUENCE_NOTE = "UPDATE_SEQUENCE_NOTE";

export const PANE_RESIZE = "PANE_RESIZE";
export const UPDATE_TIMESLOTS = "UPDATE_TIMESLOTS";
export const CHANGE_DAYSLOT_TITLE = "CHANGE_DAYSLOT_TITLE";
export const CHANGE_DAYSLOT_TAB = "CHANGE_DAYSLOT_TAB";
export const CHANGE_DAYSLOT_NOTE = "CHANGE_DAYSLOT_NOTE";
export const SET_NEX_SUBJECT = "SET_NEX_SUBJECT";
export const RESET_INTERNAL = "RESET_INTERNAL";
export const CHANGE_HOMEWORK = "CHANGE_HOMEWORK";
export const CHANGE_TEACH_TOOLS = "CHANGE_TEACH_TOOLS";
export const DELETE_PANES = "DELETE_PANES";
export const DELETE_PANE = "DELETE_PANE";
export const MOVE_DAYSLOT_DATA = "MOVE_DAYSLOT_DATA";

const DELETE_SEQUENCE_SUCCESS = "DELETE_SEQUENCE_SUCCESS";
const DELETE_SEQUENCE_ERROR = "DELETE_SEQUENCE_ERROR";

export const RESORT_PANES = "RESORT_PANES";

export const CHANGE_MATERIAL_LIST = "CHANGE_MATERIAL";
export const CHANGE_DIKTA_TEXT = "CHANGE_DIKTA_TEXT";
export const CHANGE_LEHRPLANBEZUG = "CHANGE_LEHRPLANBEZUG";

export const CHANGE_SEQUENCE_ITEM_DIDAKT = "CHANGE_SEQUENCE_ITEM_DIDAKT";
export const UPDATE_SEQUENCE_ITEM_DATA = "UPDATE_SEQUENCE_ITEM_DATA";

// Material Actions
export const MATERIAL_ACTIVE_TAB = "MATERIAL_ACTIVE_TAB";

export const setMaterialActiveTab = (value) =>
  // materialActiveTabType
  ({
    type: MATERIAL_ACTIVE_TAB,
    value,
  });

export function changeSequenceItemDiktat(text) {
  return (dispatch) =>
    dispatch({
      type: CHANGE_SEQUENCE_ITEM_DIDAKT,
      text,
    });
}

export function resort_panes(panes) {
  return {
    type: RESORT_PANES,
    panes,
  };
}

export function updateTimeSlots() {
  return (dispatch, getState) => {
    const state = getState();

    const payload = {
      sequence_item_id: state.sequences.active_sequence_item.id,
      timeslots: state.sequences.timeslots,
      schoolyear: state.current_schoolyear.year,
    };

    const { token } = getState().authentication;
    return fetch(URLS.API_SEQUENCES_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch({
              type: UPDATE_TIMESLOTS,
              sequences: data.sequence,
            });
          });
        } else {
          response.json().then((error) => {
            dispatch({
              type: UPDATE_TIMESLOTS,
              sequences: "",
              message: error,
            });
          });
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          "Technischer Fehler!\nDeine Daten konnten nicht gespeichert werden, da der Server nicht erreichbar ist.\nBitte wende dich an unseren Support",
        );
      });
  };
}

/**
 *
 * @param user_id
 * @param sequence_id
 * @return {function(*=)}
 */
export function deleteSequences(user_id, sequence_id, schoolyear) {
  return (dispatch) =>
    fetch(
      `${URLS.API_SEQUENCES_URL}/move/${user_id}/${sequence_id}/${schoolyear}`,
      {
        method: "DELETE",
        headers: { Authorization: `bearer ${Auth.getToken()}` },
      },
    ).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: DELETE_SEQUENCE_SUCCESS,
            data,
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: DELETE_SEQUENCE_ERROR,
            error,
          });
        });
      }
    });
}

/**
 * set the new choosed day slot id and regenerate data
 * @param id
 * @return {{type: string, id: *}}
 */
export function changeDaySlotTitle(id, title) {
  return {
    type: CHANGE_DAYSLOT_TITLE,
    id,
    title,
  };
}

/**
 * set the new choosed day slot id and regenerate data
 * @param id
 * @return {{type: string, id: *}}
 */
export function changeDaySlotTab(id, tab) {
  return {
    type: CHANGE_DAYSLOT_TAB,
    id,
    tab,
  };
}

/**
 * set the new choosed day slot id and regenerate data
 * @param id
 * @return {{type: string, id: *}}
 */
export function changeDaySlotNote(id, note) {
  return {
    type: CHANGE_DAYSLOT_NOTE,
    id,
    note,
  };
}

/**
 * set the new choosed day slot id and regenerate data
 * @param id
 * @return {{type: string, id: *}}
 */
export function setDaySlotId(
  id,
  slot_duration,
  dayslotdate,
  hours_text,
  clicked_idx,
  created_tab_new = false,
) {
  return {
    type: SET_DAYSLOT_ID,
    id,
    slot_duration,
    dayslotdate,
    hours_text,
    clicked_idx,
    created_tab_new,
  };
}

export function addPane(duration) {
  return {
    type: ADD_PANE_TO_SLOT,
    duration,
  };
}

export function deletePanes() {
  return {
    type: DELETE_PANES,
  };
}
export function deletePane(id) {
  return {
    type: DELETE_PANE,
    id,
  };
}

export function changePaneText(id, type, text) {
  return {
    type: CHANGE_PANE_TEXT,
    id,
    change_type: type,
    text,
  };
}

export function changeSequenceNote(note) {
  return {
    type: UPDATE_SEQUENCE_NOTE,
    note,
  };
}

/**
 * set material list
 * @param id
 * @param list
 * @return {{type: string, id: *, change_type, text: (*|Array|string|string)}}
 */
export function update_material(id, tags) {
  return {
    type: CHANGE_MATERIAL_LIST,
    id,
    tags,
  };
}

export function paneResize(id, duration) {
  return {
    type: PANE_RESIZE,
    id,
    duration,
  };
}

export function activeSequenceView(
  sequenceItemId,
  isAbWeekActive,
  currentAbSettings,
  activeInitDate,
  ueId,
  ueIdx,
) {
  return (dispatch, getState) => {
    const state = getState();
    const schoolWithHolidays = selectSchoolWithHolidays(state);
    const payload = {
      startDate: DateTime.fromISO(schoolWithHolidays.startDate).toISODate(),
      endDate: DateTime.fromISO(schoolWithHolidays.endDate).toISODate(),
      holidays: schoolWithHolidays.holidays,
    };

    return fetch(`${URLS.API_SEQUENCES_URL}/${sequenceItemId}`, {
      method: "PATCH",
      headers: {
        Authorization: `bearer ${Auth.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // 1: first check if sequence_item data object exists
          if (data.sequenceItem && data.sequenceItem.data) {
            const lessonDuration = selectLessonDuration(state);

            dispatch({
              type: ACTIVE_SEQUENCE,
              data,
              school: schoolWithHolidays,
              isAbWeekActive,
              currentAbSettings,
              ueId,
              ueIdx,
              activeInitDate,
              lessonDuration,
            });
          }
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: ACTIVE_SEQUENCE_ERROR_PATCH,
            message: error,
            show404: true,
          });
        });
      }
    });
  };
}

export function updateSequenceItemData(sequenceItemId, sequenceItem) {
  const url = `${URLS.API_SEQUENCE_ITEMS_UPDATE}/${sequenceItemId}`;
  return (dispatch) =>
    fetch(url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(sequenceItem),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: UPDATE_SEQUENCE_ITEM_DATA,
            sequence_item: data.itemData,
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: UPDATE_SEQUENCE_ITEM_DATA,
            sequence_item: sequenceItem,
            message: error,
          });
        });
      }
    });
}

export function changeTeachTools(text) {
  return {
    type: CHANGE_TEACH_TOOLS,
    text,
  };
}

export function changeHomework(text) {
  return {
    type: CHANGE_HOMEWORK,
    text,
  };
}

export function changeDidaktHinter(id, text) {
  return {
    type: CHANGE_DIKTA_TEXT,
    text,
    id,
  };
}

export function changeLehrplanbezug(tags) {
  return {
    type: CHANGE_LEHRPLANBEZUG,
    tags,
  };
}

export function moveDayslotData(data) {
  return {
    type: MOVE_DAYSLOT_DATA,
    data,
  };
}
