import React from "react";
import Modal from "react-modal";
import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { connect } from "react-redux";
import TimeTableColumns from "./TimeTableColumn";
import TimeTableColumnLabel from "./TimeTableColumnLabel";
import ClassesSubjectRow from "./ClassesSubjectRow";
import Auth from "../../../services/Auth";
import * as ACTIONS from "../../../../features/timetable/actions";
import * as CACTIONS from "../../../../features/classes/actions";
import { sortClassesAlphabetically } from "../../../utils/assortments";
import { selectSettings } from "../../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { api } from "../../../../services/api";

const mapStateToProps = (state) => ({
  schoolyear: selectCurrentSchoolyear(state),
  subjects: state.subjects,
  classes: sortClassesAlphabetically(state.classes),
  timetable: state.timetable,
  settings: selectSettings(state),
});

const mapDispatchToProps = {
  handleEntries: ACTIONS.handleEntries,
  clearAll: ACTIONS.clearAll,
  upsertTimetable: ACTIONS.upsertTimetable,
  upsertTimetableSaturday: ACTIONS.upsertTimetable,
  fetchTimetable: ACTIONS.fetchTimetable,
  updateClassListItem: CACTIONS.updateClassListItem,
  updateSettings: api.endpoints.updateSettings.initiate,
};

const ModalStyles = {
  overlay: {
    backgroundColor: "rgba(64, 184, 126, 0.7)",
  },
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
};
Modal.setAppElement("#appMountPoint");

const TimeTableDayLabels = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

class AddTimetableForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalHoverClass: "",
      modalDay: "",
      modalHour: "",
      hour: "",
      day: null,
      timeTableLabelCnt: 7,
      preselect_class_id: false,
      preselect_subject_id: false,
      preselect_class_id_b: false,
      preselect_subject_id_b: false,
      schoolyear: props.schoolyear,
      ab_weeks: false,
      ab_weeks_change_state: false,
      ab_settings_edit: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addModalHoverClass = this.addModalHoverClass.bind(this);
    this.removeModalHoverClass = this.removeModalHoverClass.bind(this);
    this.addTimeTableLabel = this.addTimeTableLabel.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.resetData = this.resetData.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.changeSaturday = this.changeSaturday.bind(this);
    this.entriesABWeeks = this.entriesABWeeks.bind(this);
    this.changeABSettings = this.changeABSettings.bind(this);
    this.changeABSettingsHandler = this.changeABSettingsHandler.bind(this);
    this.cleanUpABProperties = this.cleanUpABProperties.bind(this);
    this.setUpABProperties = this.setUpABProperties.bind(this);
  }

  /**
   * fetch data if needed
   */
  componentDidMount() {
    let length = this.findMaxHourFromList();
    const hasABWeeksSettings = this.hasABWeeksSettings();
    if (length < 7) length = 7;
    this.setState({
      timeTableDefCnt: length,
      ab_weeks: hasABWeeksSettings,
      ab_weeks_change_state: hasABWeeksSettings,
    });

    if (this.props.fetchData) {
      this.props.fetchTimetable(Auth.getUserId(), this.state.schoolyear);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      this.setState({
        ab_weeks: this.hasABWeeksSettings(),
      });
    }
  }

  /**
   * click on subject inside modal -> create or update an entry inside timetable
   * @param obj
   */
  handleItemClick(obj) {
    const _presel_day = this.state.day;
    let _presel_class_id = this.state.preselect_class_id;
    let _presel_subject_id = this.state.preselect_subject_id;

    if (this.state.ab_weeks) {
      if (obj.week_type === "B") {
        _presel_class_id = this.state.preselect_class_id_b;
        _presel_subject_id = this.state.preselect_subject_id_b;
      }
    }

    if (this.state.ab_weeks) {
      if (obj.week_type === "B") {
        this.setState({
          preselect_class_id_b: obj.class_id,
          preselect_subject_id_b: obj.subject_id,
        });
      } else {
        this.setState({
          preselect_class_id: obj.class_id,
          preselect_subject_id: obj.subject_id,
        });
      }
    } else {
      this.setState({
        preselect_class_id: obj.class_id,
        preselect_subject_id: obj.subject_id,
      });
    }

    /*
    Bugifx Marko: wenn bei einen bereits geplanten Tag der Tag mit einen Fach aus einer anderen Klasse
    neu geplant wird, dann wurde die vorherige geplante Stunde nicht in der anderen Klasse & Fach verworfen.
    Der folgende Fix prüft, ob zu den geklickten Tag und Stunde im Stundenplan bereits was von einer anderen Klasse
    existiert, wenn ja wird dieser eintrag gelöscht.
    */

    // 1) einträge ermitteln wo ein gleicher Stundenplan eintrag von einer anderen klasse besteht
    const cleanupClass = this.props.classes.find((classlist) =>
      classlist.subjects.some((sub) =>
        sub.schooldays.some(
          (item) =>
            item.day === this.state.day &&
            item.hour === this.state.hour &&
            classlist._id !== obj.class_id,
        ),
      ),
    );

    // wenn wir was gefunden haben, bereinigen wir die schooldays (stundenplan) liste
    if (cleanupClass) {
      const cleanSubjectSchooldays = cleanupClass.subjects.map((su) => ({
        ...su,
        schooldays: this.state.ab_weeks
          ? su.schooldays.filter(
              (sc) =>
                !(
                  sc.week_type === obj.week_type &&
                  sc.day === this.state.day &&
                  sc.hour === this.state.hour
                ),
            )
          : su.schooldays.filter(
              (sc) =>
                !(sc.day === this.state.day && sc.hour === this.state.hour),
            ),
      }));
      // falls wir was berinigt haben, im backend ändern.
      if (cleanSubjectSchooldays) {
        const cleanClassSubjects = {
          ...cleanupClass,
          subjects: cleanSubjectSchooldays,
        };
        this.props.updateClassListItem(
          cleanClassSubjects,
          this.state.schoolyear,
        );
      }
    }

    // prepare data to add or remove schooldays
    // update der klassenliste um die Stunden zu ergänzen/löschen
    let other_weektype = "default";
    if (this.state.ab_weeks) {
      other_weektype = obj.week_type === "A" ? "B" : "A";
    }

    const schoolday_entry = {
      day: this.state.day,
      day_name: this.state.modalDay,
      hour: this.state.hour,
      week_type: obj.week_type !== "default" ? obj.week_type : "default",
    };

    // prüfen ob ein anderer ab tag in irgendeinen fach existiert, wenn nein kann dieser dupliziert werden.
    // b wwochen welche geclont wurden sollen nicht matchen
    const check_exists_list = this.props.classes.slice();

    let other_weektype_exists = false;
    check_exists_list.forEach((elementc) => {
      elementc.subjects.forEach((subsc) => {
        subsc.schooldays.forEach((sdc) => {
          if (
            sdc.day === schoolday_entry.day &&
            sdc.hour === schoolday_entry.hour &&
            sdc.week_type === other_weektype &&
            !sdc.week_clone
          ) {
            other_weektype_exists = true;
          }
        });
      });
    });

    // find class and subjects via internal id
    let class_entry = this.props.classes.find((p) => p._id === obj.class_id);

    const update_class = class_entry;
    if (update_class) {
      // this.props.classes list

      /**
       * abgleich mit der liste aus timetable damit hier entsprechend wieder aufgeräumt wird.
       * stundenplan einträge welche neu mit inhalt belegt wurden, wurden nicht gelöscht.
       */
      const check_list = [];
      this.props.timetable.entries.forEach((elem) => {
        // alle datensätze zum aktuellen tag, klasse und fach ermitteln

        if (this.state.ab_weeks) {
          if (Number(elem.day) === _presel_day) {
            // weeks
            if (
              elem.classes_ab_weeks &&
              elem.classes_ab_weeks.A &&
              elem.classes_ab_weeks.A._id === obj.class_id &&
              elem.classes_ab_weeks.A.subject._id === obj.subject_id
            ) {
              check_list.push({
                day: elem.day,
                day_name: elem.day_name,
                hour: elem.hour,
                week_type: "A",
              });
            }
            // B Weeks
            if (
              elem.classes_ab_weeks &&
              elem.classes_ab_weeks.B &&
              elem.classes_ab_weeks.B._id === obj.class_id &&
              elem.classes_ab_weeks.B.subject._id === obj.subject_id
            ) {
              check_list.push({
                day: elem.day,
                day_name: elem.day_name,
                hour: elem.hour,
                week_type: "B",
              });
            }
          }
        } else if (
          Number(elem.day) === _presel_day &&
          elem.class._id === obj.class_id &&
          elem.class.subject._id === obj.subject_id
        ) {
          check_list.push({
            day: elem.day,
            day_name: elem.day_name,
            hour: elem.hour,
            week_type: obj.week_type !== "default" ? obj.week_type : "default",
          });
        }
      });

      const check_list_2 = [];
      const check_list_others = [];
      update_class.subjects.forEach((element) => {
        if (element._id === obj.subject_id) {
          element.schooldays.forEach((sd) => {
            if (Number(sd.day) === _presel_day) {
              // alles zu dem gesuchten Tag
              check_list_2.push(sd);
            } else {
              // alle anderen
              check_list_others.push(sd);
            }
          });

          if (check_list.length !== check_list_2.length) {
            // data not the same structure cleanup....
            element.schooldays = [];
            element.schooldays = check_list.concat(check_list_others);
          }
        }
      });

      /**
       * wir haben an dem geklickten element bereits einen geplanten tag. dieser muss gelöscht werden, analog
       * den freistunde prozess button.
       */
      if (_presel_class_id && _presel_subject_id) {
        // reset the day inside class list

        update_class.subjects.forEach((element, index) => {
          if (
            element._id === _presel_subject_id &&
            element.schooldays &&
            element.schooldays.length > 0
          ) {
            const tmp_list = [];
            element.schooldays.forEach((sd) => {
              if (this.state.ab_weeks) {
                // with ab weeks
                // check if a setting or other weektype a clone exists
                if (
                  (sd.day === schoolday_entry.day &&
                    sd.hour === schoolday_entry.hour &&
                    sd.week_type === schoolday_entry.week_type) ||
                  (other_weektype === "B" &&
                    sd.day === schoolday_entry.day &&
                    sd.hour === schoolday_entry.hour &&
                    sd.week_type === other_weektype &&
                    sd.week_clone === true)
                ) {
                  // if case does nothing
                } else {
                  tmp_list.push(sd);
                }
              } else if (
                // default without ab weeks active
                sd.day === schoolday_entry.day &&
                sd.hour === schoolday_entry.hour
              ) {
                // if case does nothing
              } else {
                tmp_list.push(sd);
              }
            });
            update_class.subjects[index].schooldays = tmp_list;
            // update_class.subjects[sindex].schooldays = tmp_list;
          }
        });
      }

      /**
       * fügt die geklickte Fach/Klasse der Schulstunde hinzu.
       */
      update_class.subjects.forEach((element, index) => {
        if (element._id === obj.subject_id) {
          if (update_class.subjects[index].schooldays) {
            // add schoolday only if not exists
            if (this.state.ab_weeks) {
              // new with ab

              // prüfen ob der andere weektype vorhanden ist, falls nicht, beide identisch anlegen.
              const exists_other_week = update_class.subjects[
                index
              ].schooldays.find(
                (sd) =>
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour &&
                  sd.week_type === other_weektype,
              );

              const exists = update_class.subjects[index].schooldays.find(
                (sd) =>
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour &&
                  sd.week_type === schoolday_entry.week_type,
              );

              if (!exists) {
                update_class.subjects[index].schooldays.push(schoolday_entry);

                // nur wenn A geklickt wurde und der B nicht existiert hinzufügen

                if (
                  !exists_other_week &&
                  !other_weektype_exists &&
                  other_weektype === "B"
                ) {
                  const other_entry = { ...schoolday_entry };
                  other_entry.week_type = other_weektype;
                  other_entry.week_clone = true;
                  update_class.subjects[index].schooldays.push(other_entry);
                }
              }
            } else {
              const exists = update_class.subjects[index].schooldays.find(
                (sd) =>
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour,
              );
              if (!exists) {
                update_class.subjects[index].schooldays.push(schoolday_entry);
              }
            }
          } else {
            update_class.subjects[index].schooldays = [schoolday_entry];

            if (this.state.ab_weeks) {
              // noch nichts vorhanden - anderen weektype als clone erstmal damit a/b komplett sind
              if (!other_weektype_exists && other_weektype === "B") {
                const other_entry = { ...schoolday_entry };
                other_entry.week_type = other_weektype;
                other_entry.week_clone = true;
                update_class.subjects[index].schooldays.push(other_entry);
              }
            }
          }
        }
      });
      this.props.updateClassListItem(update_class, this.state.schoolyear);
    } else {
      let founded_idx = "";
      // reset the day inside class list
      const class_list = immutableUpdate(this.props.classes, (draft) => {
        draft.forEach((element, index) => {
          if (element._id === _presel_class_id) {
            if (element.subjects.length > 0) {
              element.subjects.forEach((subs, sindex) => {
                if (
                  subs._id === _presel_subject_id &&
                  subs.schooldays &&
                  subs.schooldays.length > 0
                ) {
                  const tmp_list = [];

                  // prüfen ob es zu einen A eintrag einen clone gibt, wenn ja muss der B Clone mit gelöscht werden.
                  let delete_with_clone = false;
                  if (
                    this.state.ab_weeks &&
                    schoolday_entry.week_type === "A"
                  ) {
                    subs.schooldays.forEach((sd) => {
                      if (
                        sd.day === schoolday_entry.day &&
                        sd.hour === schoolday_entry.hour &&
                        sd.week_type === "B" &&
                        sd.week_clone === true
                      ) {
                        delete_with_clone = true;
                      }
                    });
                  }

                  subs.schooldays.forEach((sd) => {
                    if (this.state.ab_weeks) {
                      if (delete_with_clone) {
                        if (
                          sd.day === schoolday_entry.day &&
                          sd.hour === schoolday_entry.hour
                        ) {
                          founded_idx = index;
                        } else {
                          tmp_list.push(sd);
                        }
                      } else if (
                        sd.day === schoolday_entry.day &&
                        sd.hour === schoolday_entry.hour &&
                        sd.week_type === schoolday_entry.week_type
                      ) {
                        founded_idx = index;
                      } else {
                        tmp_list.push(sd);
                      }
                    } else if (
                      sd.day === schoolday_entry.day &&
                      sd.hour === schoolday_entry.hour
                    ) {
                      founded_idx = index;
                    } else {
                      tmp_list.push(sd);
                    }
                  });

                  draft[index].subjects[sindex].schooldays = tmp_list;
                }
              });
            }
          }
        });
      });

      if (founded_idx !== "") {
        this.props.updateClassListItem(
          class_list[founded_idx],
          this.state.schoolyear,
        );
      }
    }

    if (class_entry) {
      const subject_entry = this.props.subjects.raw.find(
        (p) => p._id === obj.subject_id,
      );
      class_entry.subject = subject_entry;
    } else {
      class_entry = "clear"; // set to clear for del from timetable
    }

    // zum update der timetable list
    let new_obj = {
      day: this.state.day,
      day_name: this.state.modalDay,
      hour: this.state.hour,
      class: class_entry,
    };

    if (this.state.ab_weeks) {
      new_obj = this.entriesABWeeks(new_obj, obj.week_type);
    }

    this.props.handleEntries(new_obj, obj.week_type);

    this.props.upsertTimetable(this.props.timetable, this.state.schoolyear);

    this.closeModal();
  }

  /**
   * bei aktivierung und bestehenden stundenplan alles
   */
  setUpABProperties() {
    const newTimetable = immutableUpdate(this.props.timetable, (draft) => {
      draft.entries.forEach((elem) => {
        if (elem.class) {
          const obj = elem.class;
          elem.classes_ab_weeks = { A: obj, B: { ...obj, week_clone: true } };
        }
      });
    });
    this.props.upsertTimetable(newTimetable, this.state.schoolyear, false);

    this.props.classes.forEach((element) => {
      const newClass = immutableUpdate(element, (draft) => {
        draft.subjects.forEach((subs) => {
          if (subs.schooldays) {
            const b_weeks_list = [];
            subs.schooldays.forEach((sd) => {
              sd.week_type = "A";
              const b_weeks_clone = { ...sd, week_type: "B", week_clone: true };
              b_weeks_list.push(b_weeks_clone);
            });
            subs.schooldays.push(...b_weeks_list);
          }
        });
      });
      this.props.updateClassListItem(newClass, this.state.schoolyear);
    });
  }

  /**
   * open an modal layer with clicked hour and date informations
   * @param hour
   * @param day
   * @param class_id
   * @param subject_id
   */
  openModal(
    hour,
    day,
    class_id = false,
    subject_id = false,
    class_id_b = false,
    subject_id_b = false,
  ) {
    this.setState({
      modalIsOpen: true,
      modalDay: TimeTableDayLabels[day],
      modalHour: hour + 1,
      hour,
      day,
      preselect_class_id: class_id,
      preselect_subject_id: subject_id,
      preselect_class_id_b: class_id_b,
      preselect_subject_id_b: subject_id_b,
    });
  }

  /**
   * not yet implemented
   */
  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  toggleABSettingsEdit(status) {
    if (status === 0) {
      // abbrechen - evtl änderungen reseten
      this.setState({
        ab_settings_edit: status,
        ab_weeks_change_state: this.state.ab_weeks, // <- reset to default
      });
    } else {
      // open
      this.setState({
        ab_settings_edit: status,
      });
    }
  }

  changeABSettingsHandler(val) {
    this.setState({
      ab_weeks_change_state: val,
    });
  }

  /**
   * change the values (on/off) for ab weeks
   * @param abWeeksTurnedOn
   */
  changeABSettings(abWeeksTurnedOn) {
    if (abWeeksTurnedOn === this.state.ab_weeks) {
      // do nothing same value
      return;
    }
    const currentYear = this.props.schoolyear;
    const active = Number(abWeeksTurnedOn);
    const newSettings = immutableUpdate(this.props.settings, (draft) => {
      const defaultValue = { active, holiday_break: false, entries: [] };
      draft.schoolyear = currentYear;

      if (!draft.settings.ab_weeks) {
        // nothing set set default value for current year
        draft.settings.ab_weeks = { [currentYear]: defaultValue };
        return;
      }
      if (!draft.settings.ab_weeks[currentYear]) {
        // other years set, set default value for current year
        draft.settings.ab_weeks[currentYear] = defaultValue;
        return;
      }
      // current year exists, set active value
      draft.settings.ab_weeks[currentYear].active = active;
    });

    if (!abWeeksTurnedOn) {
      // cleanup Ab from class list and timetable - delete b entries
      this.cleanUpABProperties();
    } else {
      this.setUpABProperties();
    }
    this.props.updateSettings(newSettings);

    this.setState({
      ab_settings_edit: false,
      ab_weeks: abWeeksTurnedOn,
    });
  }

  hasABWeeksSettings() {
    const { props } = this;
    if (props.settings && props.settings.settings) {
      // settings exists, check if have ab active for the current year
      const year = props.schoolyear;
      const { settings } = props.settings;
      if (Number(settings.ab_weeks?.[year]?.active) === 1) {
        return true;
      }
    }
    return false;
  }

  /**
   * close modal layer and reset presentation data
   */
  closeModal() {
    this.setState({
      modalIsOpen: false,
      modalDay: "",
      modalHour: "",
      hour: "",
      day: null,
      preselect_class_id: false,
      preselect_subject_id: false,
      preselect_class_id_b: false,
      preselect_subject_id_b: false,
    });
  }

  /**
   * add an time table hour label to the list
   * @param hours
   */
  addTimeTableLabel(hours) {
    let cnt = this.state.timeTableLabelCnt;
    if (hours >= cnt) {
      cnt += 1;
      this.setState({
        timeTableLabelCnt: cnt,
      });
    }
  }

  /**
   * bei der Umstellung wieder alles auf einen wert
   * nur a wochen zu normalen machen
   */
  cleanUpABProperties() {
    // alle a class elemente ermitteln und bei entries wieder rein.
    const newEntries = this.props.timetable.entries
      .filter((elem) => elem.classes_ab_weeks && elem.classes_ab_weeks.A)
      .map((elem) => ({
        day: elem.day,
        day_name: elem.day_name,
        hour: elem.hour,
        class: elem.classes_ab_weeks.A,
      }));
    this.props.upsertTimetable(
      { ...this.props.timetable, entries: newEntries },
      this.state.schoolyear,
      false,
    );
    this.props.classes.forEach((element) => {
      const newClass = immutableUpdate(element, (draft) => {
        draft.subjects.forEach((subs, sindex) => {
          const new_school_list = subs.schooldays
            ? subs.schooldays
                .filter((sd) => sd.week_type === "A")
                .map((sd) => ({
                  day: sd.day,
                  day_name: sd.day_name,
                  hour: sd.hour,
                }))
            : [];

          draft.subjects[sindex].schooldays = new_school_list;
        });
      });
      this.props.updateClassListItem(newClass, this.state.schoolyear);
    });
  }

  /**
   * Ergänzen und löschen von Elementen. Wird das A Element geelöscht, prüfen wir ob es einen
   * identischen B Eintrag gibt und löschen diesen mit.
   *
   * @TODO: prüfen
   *
   *
   * @param obj
   * @param week_type
   * @returns {*}
   */
  entriesABWeeks(obj, week_type) {
    const entries = this.props.timetable.entries.slice();

    const other_weektype = week_type === "A" ? "B" : "A";

    const exists_idx = entries.findIndex(
      (el) =>
        Number(el.day) === Number(obj.day) &&
        Number(el.hour) === Number(obj.hour),
    );
    // falls vorhanden, ersetzen oder löschen
    if (exists_idx !== -1 && entries[exists_idx].classes_ab_weeks) {
      const exists_entry = entries[exists_idx].classes_ab_weeks;

      // löschen
      if (obj.class === "clear") {
        if (exists_entry[week_type]) {
          const clear_class_id = exists_entry[week_type]._id;
          const clear_subject_id = exists_entry[week_type].subject._id;

          delete exists_entry[week_type];

          // prüfen ob der B Eintrag das gleiche (Fach, Klasse) ist, wenn ja mit löschen.
          if (week_type === "A") {
            if (
              exists_entry.B &&
              exists_entry.B._id === clear_class_id &&
              exists_entry.B.subject._id === clear_subject_id &&
              exists_entry.B.week_clone
            ) {
              delete exists_entry.B;
            }
          }
        }
      } else {
        // hinzufügen
        exists_entry[week_type] = obj.class;

        // wenn a geklickt und  B nicht vorhanden -> duplizieren
        // wenn a geklickt und b nicht vorhanden oder b ein clone von a ist -> duplizieren

        if (
          (!exists_entry[other_weektype] && other_weektype === "B") ||
          (exists_entry[other_weektype] &&
            other_weektype === "B" &&
            exists_entry[other_weektype].week_clone)
        ) {
          exists_entry[other_weektype] = obj.class;
          exists_entry[other_weektype].week_clone = true;
        }

        obj.classes_ab_weeks = exists_entry;
      }

      // neu hinzufügen
    } else {
      const classes_ab_weeks = {};
      classes_ab_weeks[week_type] = obj.class;

      // nur wenn A geklickt wurde und noch nichts anderes existiert
      if (other_weektype === "B") {
        if (!classes_ab_weeks[other_weektype]) {
          classes_ab_weeks[other_weektype] = {};
        }

        classes_ab_weeks[other_weektype] = obj.class;
        classes_ab_weeks[other_weektype].week_clone = true;
      }

      obj.classes_ab_weeks = classes_ab_weeks;
    }
    return obj;
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.nextSection();
  }

  prevSection() {
    this.props.prevSection();
  }

  addModalHoverClass() {
    this.setState({
      modalHoverClass: "hover_item",
    });
  }

  removeModalHoverClass() {
    this.setState({
      modalHoverClass: "",
    });
  }

  findMaxHourFromList(day) {
    const def_cnt = this.state.timeTableDefCnt;
    const list =
      this.props.timetable && this.props.timetable.entries.length > 0
        ? this.props.timetable.entries
        : [];
    let res = 0;

    if (typeof day !== "undefined") {
      const tt = this.props.timetable.entries.filter(
        (entry) => Number(entry.day) === day,
      );
      if (tt.length > 0) {
        res = Math.max(...tt.map((o) => o.hour));
      }
    } else {
      res = Math.max(...list.map((o) => o.hour));
    }

    if (def_cnt > res) {
      res = def_cnt;
    }
    return res;
  }

  resetData() {
    this.props.clearAll();
  }

  /**
   *
   * @param value
   */
  changeSaturday(value) {
    const obj = { ...this.props.timetable };
    obj.saturday = Number(value) === 1;
    this.props.upsertTimetableSaturday(obj, this.state.schoolyear, false);
  }

  /**
   * create the columns from monday to saturday
   * @return {Array}
   */
  renderColumn(saturday_css_class) {
    const cnt = this.state.timeTableLabelCnt;
    const label_count_list = this.findMaxHourFromList();

    let label_count = 0;
    if (label_count_list > cnt) {
      label_count = label_count_list;
    } else {
      label_count = cnt;
    }

    const column_items = [];
    let tt = {};

    column_items.push(
      <TimeTableColumnLabel cnt={label_count} key="tt-column-label-0" />,
    ); // first column are the labels of hours

    for (let i = 0; i <= 4; i += 1) {
      // check if an timetable entry exists for current day.
      tt = this.props.timetable.entries.filter(
        (entry) => Number(entry.day) === i,
      );

      column_items.push(
        <TimeTableColumns
          ab_weeks={this.state.ab_weeks}
          timetable={tt}
          addTimeTableLabel={this.addTimeTableLabel}
          day={i}
          openModal={this.openModal}
          show_saturday=""
          cnt=""
          key={`tt-column-${i}`}
        />,
      );
    }
    // push saturday

    tt = this.props.timetable.entries.filter(
      (entry) => Number(entry.day) === 5,
    );
    const rcnt = this.findMaxHourFromList(5);
    column_items.push(
      <TimeTableColumns
        ab_weeks={this.state.ab_weeks}
        timetable={tt}
        addTimeTableLabel={this.addTimeTableLabel}
        day={5}
        openModal={this.openModal}
        show_saturday={saturday_css_class}
        cnt={rcnt}
        key="tt-column-5"
      />,
    );
    return column_items;
  }

  render() {
    const nextenabled = this.props.classes.length > 0;

    // saturday stuff
    const isSaturdayValue =
      this.props.timetable && this.props.timetable.saturday ? 0 : 1;
    const defaultCheckedSaturday = !!(
      this.props.timetable && this.props.timetable.saturday
    );
    let saturday_css_class = "";
    let hasSaturdayClass = "with_saturday";

    if (!defaultCheckedSaturday) {
      saturday_css_class = "hidden";
      hasSaturdayClass = "";
    }

    // last modal row
    const last_modal = {
      title: "",
      subjects: [{ title: "Leerstunde", color: "#F2F2F2" }],
    };
    const hover_css = this.state.modalHoverClass;

    let ab_mode_css = "";
    let ab_edit_css = "";
    let ab_active_css = "";
    let ab_inactive_css = "";

    const { ab_settings_edit, ab_weeks, ab_weeks_change_state } = this.state;

    const canSaveTimetableMode = ab_weeks !== ab_weeks_change_state;

    if (ab_weeks_change_state) {
      ab_inactive_css = "";
      ab_active_css = "active";
    } else {
      ab_inactive_css = "active";
      ab_active_css = "";
    }

    if (ab_settings_edit) {
      ab_mode_css = "hide";
      ab_edit_css = "show";
    } else {
      ab_mode_css = "show";
      ab_edit_css = "hide";
    }

    return (
      <div id="timetableView" className={hasSaturdayClass}>
        <div className="setting-ab-weeks">
          <div className={`info-wrapper ${ab_mode_css}`}>
            <span className="mode">Modus</span>
            <span className="info">
              {!ab_weeks ? (
                <span>gleicher Stundenplan für alle Wochen</span>
              ) : (
                <span>A/B Wochen</span>
              )}
            </span>
            <span className="edit">
              <a
                onClick={() => {
                  this.toggleABSettingsEdit(1);
                }}
              >
                <img src="/assets/images/edit-icon.png" className="form-icon" />
              </a>
            </span>
          </div>
          <div className={`edit-wrapper ${ab_edit_css}`}>
            <div className="mode">Modus</div>

            <span className={`item ${ab_inactive_css}`}>
              <input
                type="radio"
                name="ab_setting"
                id="input-equal-weeks"
                value="0"
                checked={!ab_weeks_change_state}
                onChange={() => {
                  this.changeABSettingsHandler(false);
                }}
              />
              <label htmlFor="input-equal-weeks">
                gleicher Stundenplan für alle Wochen
              </label>
            </span>

            <span className={`item ${ab_active_css}`}>
              <input
                type="radio"
                name="ab_setting"
                id="input-ab-weeks"
                value="1"
                checked={ab_weeks_change_state}
                onChange={() => {
                  this.changeABSettingsHandler(true);
                }}
              />
              <label htmlFor="input-ab-weeks">A/B Wochen</label>
            </span>

            <div className="btn-wrapper">
              {!canSaveTimetableMode ? (
                <button className="btn btn-dark" disabled>
                  speichern
                </button>
              ) : (
                <button
                  className="btn btn-step active"
                  onClick={() => {
                    this.changeABSettings(ab_weeks_change_state);
                  }}
                >
                  speichern
                </button>
              )}
              &nbsp; &nbsp; &nbsp;
              <button
                className="btn btn-dark"
                onClick={() => {
                  this.toggleABSettingsEdit(0);
                }}
              >
                abbrechen
              </button>
            </div>
          </div>
        </div>

        <div className="modal-wrapper-out" id="modal-wrapper-out">
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={ModalStyles}
            ariaHideApp={false}
            contentLabel="Stundenplan"
          >
            <div className={`modal-wrapper ${hover_css}`}>
              <div className="modal-header timetable">
                <span className="day">{this.state.modalDay},</span>
                <span className="hour">{this.state.modalHour}. Stunde</span>
              </div>

              {this.state.ab_weeks ? (
                <Tabs className="tab-main-wrapper">
                  <div className="tab-links">
                    <TabLink to="tab_a_week">A Woche</TabLink>
                    <TabLink to="tab_b_week">
                      B Woche (falls abweichend)
                    </TabLink>
                  </div>

                  <TabContent for="tab_a_week">
                    <div className="closer">
                      <a
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        <img src="/assets/images/close_modal.png" />
                      </a>
                    </div>

                    {this.props.classes.map((p, i) => (
                      <ClassesSubjectRow
                        {...p}
                        key={`cs-arow-${i}`}
                        addModalHoverClass={this.addModalHoverClass}
                        removeModalHoverClass={this.removeModalHoverClass}
                        handleItemClick={this.handleItemClick}
                        nr={i}
                        week_type="A"
                        preselect_class={this.state.preselect_class_id}
                        preselect_subject={this.state.preselect_subject_id}
                      />
                    ))}
                    <ClassesSubjectRow
                      {...last_modal}
                      key="cs-arow-9999"
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.handleItemClick}
                      nr="9999"
                      week_type="A"
                      preselect_class={this.state.preselect_class_id}
                      preselect_subject={this.state.preselect_subject_id}
                    />
                  </TabContent>
                  <TabContent for="tab_b_week">
                    <div className="closer">
                      <a
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        <img src="/assets/images/close_modal.png" />
                      </a>
                    </div>

                    {this.props.classes.map((p, i) => (
                      <ClassesSubjectRow
                        {...p}
                        key={`cs-brow-${i}`}
                        addModalHoverClass={this.addModalHoverClass}
                        removeModalHoverClass={this.removeModalHoverClass}
                        handleItemClick={this.handleItemClick}
                        nr={i}
                        week_type="B"
                        preselect_class={this.state.preselect_class_id_b}
                        preselect_subject={this.state.preselect_subject_id_b}
                      />
                    ))}
                    <ClassesSubjectRow
                      {...last_modal}
                      key="cs-brow-9999"
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.handleItemClick}
                      nr="9999"
                      week_type="B"
                      preselect_class={this.state.preselect_class_id_b}
                      preselect_subject={this.state.preselect_subject_id_b}
                    />
                  </TabContent>
                </Tabs>
              ) : (
                <div className="defaul-tt-modal">
                  {this.props.classes.map((p, i) => (
                    <ClassesSubjectRow
                      {...p}
                      key={`cs-arow-${i}`}
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.handleItemClick}
                      nr={i}
                      week_type="A"
                      preselect_class={this.state.preselect_class_id}
                      preselect_subject={this.state.preselect_subject_id}
                    />
                  ))}
                  <ClassesSubjectRow
                    {...last_modal}
                    key="cs-arow-9999"
                    addModalHoverClass={this.addModalHoverClass}
                    removeModalHoverClass={this.removeModalHoverClass}
                    handleItemClick={this.handleItemClick}
                    nr="9999"
                    week_type="A"
                    preselect_class={this.state.preselect_class_id}
                    preselect_subject={this.state.preselect_subject_id}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>

        <div className="tt-day-wrapper">
          <div className="day-label first" />
          <div className="day-label">
            Montag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Dienstag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Mittwoch
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Donnerstag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Freitag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className={`day-label ${saturday_css_class}`}>
            Samstag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="tt-wrapper">
          {this.renderColumn(saturday_css_class)}
          <div style={{ clear: "both" }} />
        </div>

        <div className="add_column">
          Samstagsunterricht
          <input
            onChange={(e) => {
              this.changeSaturday(e.target.value);
            }}
            defaultChecked={defaultCheckedSaturday}
            type="checkbox"
            value={isSaturdayValue}
          />
        </div>
        <div className="line" />

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back">
              <a href="#" onClick={() => this.prevSection()}>
                Zurück zu Klassen
              </a>
            </div>
            <div className="next">
              <button
                disabled={!nextenabled}
                className="btn btn-step"
                onClick={() => this.nextSection()}
              >
                Schule und Bundesland
              </button>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTimetableForm);
