import { LoaderFunction, replace } from "react-router-dom";
import store, { getAuthToken } from "../store";
import { getTokenIssueDate } from "../../common/utils/jwt";
import { HandlerCtx } from "../router/data-strategy";
import { selectCurrentSchoolyear } from "../../features/current-schoolyear/current-schoolyear-slice";
import { selectSchoolRegion } from "../../features/school/reducer";
import { api } from "../../services/api";

const requireAuth: LoaderFunction = async ({ request }) => {
  const token = await getAuthToken();

  const currentUrl = new URL(request.url);
  const params = new URLSearchParams({
    from: `${currentUrl.pathname}${currentUrl.search}`,
  });
  const response = replace(`/login?${params.toString()}`);

  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!token) {
    throw response;
  }

  // force logout for older tokens
  // done for user name changes in DB, remove afterwards
  const tokenIssueDate = getTokenIssueDate(token);
  if (tokenIssueDate <= new Date("2023-10-07T20:45:00.000Z")) {
    throw response;
  }

  return null;
};

// use this loader as a central place to add (and remove) RTK Query subscriptions
// for data that is needed throughout the logged in parts of the app (more than one page)
export const loader: LoaderFunction = async (loaderArgs, handlerCtx) => {
  const { onUnload } = handlerCtx as HandlerCtx;
  // make sure we are logged in
  await requireAuth(loaderArgs);
  const state = store.getState();
  const year = selectCurrentSchoolyear(state);
  const region = selectSchoolRegion(state);

  // subscripe to needed RTK Query data
  const queryPromises = [
    store.dispatch(api.endpoints.getSettings.initiate(year)),
    store.dispatch(api.endpoints.getCurrentNote.initiate()),
    store.dispatch(
      api.endpoints.getHolidays.initiate({
        schoolyear: year,
        region,
      }),
    ),
    store.dispatch(api.endpoints.getSchool.initiate(year)),
  ];

  // make sure to remove the subscriptions when the page unloads
  onUnload(() => {
    queryPromises.forEach((promise) => promise.unsubscribe());
  });
  // wait for data to have loaded
  await Promise.all(queryPromises);

  return null;
};
