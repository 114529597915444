import React from "react";
import { SelectableGroup } from "react-selectable-fast";
import { connect } from "react-redux";
import {
  draw_timeline,
  getTimeRows,
} from "../../../../../common/components/sequences/Utils";
import SortableTimeItems from "./SortableTimeItems";
import SelectableRow from "./TimeRow";
import * as ACTIONS from "../../../../../features/sequence/actions";
import { selectSettings } from "../../../../../features/settings/settings-slice";

const mapStateToProps = (state) => ({
  active_timeslot: state.sequences.active_timeslot,
  slot_duration: state.sequences.slot_duration,
  settings: selectSettings(state),
  sortpaneid: state.sequences.sortpaneid,
  addNewPane: state.sequences.addNewPane,
  editor_idx: state.sequences.editor_idx,
  dayslotid: state.sequences.dayslotid,
  taglist: state.taglist,
});

const mapDispatchToProps = {
  paneResize: ACTIONS.paneResize,
  changePaneText: ACTIONS.changePaneText,
  resort_panes: ACTIONS.resort_panes,
  updateTimeSlots: ACTIONS.updateTimeSlots,
  deletePanes: ACTIONS.deletePanes,
  deletePane: ACTIONS.deletePane,
  update_material: ACTIONS.update_material,
  addPane: ACTIONS.addPane,
};

const SlotHeight = 100; // 5 * 20px;
const timeslotDurationInMinutes = 5; // 5 min

class TimeslotsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this._calcCurrentSlotDurations = this._calcCurrentSlotDurations.bind(this);
    this.handleOnPaneResize = this.handleOnPaneResize.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleDuringSelection = this.handleDuringSelection.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deletePane = this.deletePane.bind(this);
    this.deletePanes = this.deletePanes.bind(this);
    this.update_material = this.update_material.bind(this);
    this.updateItems = this.updateItems.bind(this);
    this.handleReOrderPanes = this.handleReOrderPanes.bind(this);
    this.handleDuringSelection = this.handleDuringSelection.bind(this);
  }

  /**
   * callback from pane resize to setup new duration on element
   * @param id index element timeslot
   * @param duration = new duration
   */
  handleOnPaneResize(id, duration) {
    this.props.paneResize(id, duration);
  }

  /**
   * called from SortableItems...
   * @param id
   * @param type
   * @param event
   */
  handleInputChange(id, type, value) {
    this.props.changePaneText(id, type, value);
  }

  handleDuringSelection(keys) {
    const item = keys[0];
    if (item) {
      const duration = Math.round(
        keys.length * Number(timeslotDurationInMinutes),
      );
      const html = `<span classname="duringSelectionText">${duration} Minuten</span>`;
      item.node.firstChild.innerHTML = html;
    }
  }

  /**
   * callback
   * @param sorted_timeslots
   */
  handleReOrderPanes(sorted_timeslots) {
    this.props.resort_panes(sorted_timeslots);
    this.props.updateTimeSlots();
  }

  /**
   * add new slot item on selected time line
   * @param keys
   */
  handleSelection(keys) {
    const selectedItems = keys;
    if (selectedItems.length > 0) {
      const duration = selectedItems.length * timeslotDurationInMinutes;
      this.props.addPane(duration);

      selectedItems.forEach((item) => {
        item.context.selectable.clearSelection();
      });
      keys[0].node.firstChild.innerText = "";
    }
  }

  /**
   * update material tags
   * @param id
   * @param tags
   */
  update_material(id, tags) {
    this.props.update_material(id, tags);
  }

  deletePane(id) {
    this.props.deletePane(id);
    this.updateItems("deletepanes");
  }

  deletePanes() {
    this.props.deletePanes();
    this.updateItems("deletepanes");
  }

  updateItems() {
    this.props.updateTimeSlots();
  }

  /**
   * calculate the real exists durations.
   * beim verschieben von tagen können diese unterschiedlich sein
   * @private
   */
  _calcCurrentSlotDurations() {
    const { slot_duration } = this.props;
    let real_duration = 0;
    if (this.props.active_timeslot.length > 0) {
      this.props.active_timeslot.forEach((elem) => {
        real_duration += parseInt(elem.duration);
      });
    }
    return real_duration > slot_duration ? real_duration : slot_duration;
  }

  render() {
    const slot_duration = this._calcCurrentSlotDurations();
    const idx_update_key = `${this.props.dayslotid}-${this.props.editor_idx}`;

    const timeline = draw_timeline(5, slot_duration); // array for draw timeline
    const timeRows = getTimeRows(5, slot_duration);
    const max_timeline_height = this.props.slot_duration * 20;
    const material_preselect =
      this.props.taglist.material_tags &&
      this.props.taglist.material_tags.length > 0
        ? this.props.taglist.material_tags
        : [];

    return (
      <div className="timeslots-component">
        <div className="timeline-wrapper">
          <div id="timeline-left">
            <ol id="rule">
              {timeline.map((elem) => {
                const time_key = `tl${elem.pos}`;
                const pos = elem.pos % 5 === 0 ? elem.pos : "";
                return (
                  <li
                    key={time_key}
                    className={elem.marker}
                    data-pos={elem.pos}
                  >
                    <span className="unit-desc">{pos}</span>
                  </li>
                );
              })}
            </ol>
          </div>

          <div id="timeline-items">
            <SortableTimeItems
              key={idx_update_key}
              panekey={idx_update_key}
              timeslots={this.props.active_timeslot}
              settings={this.props.settings}
              handleInputChange={this.handleInputChange}
              updateItems={this.updateItems}
              slotHeight={SlotHeight}
              timeslotDurationInMinutes={timeslotDurationInMinutes}
              sortpaneid={this.props.sortpaneid}
              addNewPane={this.props.addNewPane}
              handleOnResize={this.handleOnPaneResize}
              handleOnReOrder={this.handleReOrderPanes}
              maxHeight={max_timeline_height}
              handleDeleteClick={this.deletePane}
              update_material={this.update_material}
              material_preselect={material_preselect}
            />

            <SelectableGroup
              className="main-select-duration"
              clickClassName="tick"
              enableDeselect
              deselectOnEsc={false}
              allowClickWithoutSelected={false}
              duringSelection={this.handleDuringSelection}
              onSelectionClear={this.handleSelectionClear}
              onSelectionFinish={this.handleSelection}
            >
              <div className="row-items">
                {timeRows.map((item, i) => (
                  <SelectableRow
                    key={`str-${item.minute}${i}`}
                    minute={item.minute}
                  />
                ))}
              </div>
            </SelectableGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeslotsComponent);
