import { createSelector } from "@reduxjs/toolkit";
import { type Settings } from "./types";
import { api } from "../../services/api";
import { selectCurrentSchoolyear } from "../current-schoolyear/current-schoolyear-slice";

export const defaultSettings: Settings = {
  settings: {
    pdf: {},
    taktung: "20", // 1 minute taktung - 100 = 5 Minuten
    lessonDuration: {},
  },
};

// Memoize selector creation as described here
// https://redux-toolkit.js.org/rtk-query/usage/usage-without-react-hooks#memoization
const createGetSettingsSelector = createSelector(
  [selectCurrentSchoolyear],
  api.endpoints.getSettings.select,
);

export const selectSettings = createSelector(
  [(state) => state, createGetSettingsSelector],
  (state, selectSettingsResult) =>
    selectSettingsResult(state).data ?? defaultSettings,
);

export const selectLessonDuration = createSelector(
  [selectSettings, selectCurrentSchoolyear],
  (settings, year) => settings.settings.lessonDuration[year] ?? 45,
);

export const selectABWeekSettings = createSelector(
  [selectSettings, selectCurrentSchoolyear],
  (settings, year) => {
    const currentAbSettings = settings.settings.ab_weeks?.[year];
    if (currentAbSettings && Number(currentAbSettings.active) === 1) {
      return {
        ab_weeks: true,
        ab_settings: currentAbSettings,
      };
    }
    return { ab_weeks: false, ab_settings: false };
  },
);
