import { prepareCalendarDataFromTimeslots } from "../../common/components/sequences/Utils";
import { moveItems } from "./move-items";
import type { Slot, Slotday } from "./types";

/**
 * checks if an current dayslot item empty
 * @param elem
 * @returns {boolean}
 * @private
 */
const emptyHTMLString = "<p><br></p>";
function isEmptyDaySlot(slot: Slot) {
  const hasTitle = slot.title !== "";
  const hasDidakt =
    slot.didakt && slot.didakt !== "" && slot.didakt !== emptyHTMLString;

  const hasNote =
    slot.note && slot.note !== "" && slot.note !== emptyHTMLString;

  const hasHomework = slot.homework !== "" && slot.homework !== emptyHTMLString;

  const hasLehrplanBezug = slot.lehrplanbezug && slot.lehrplanbezug.length > 0;

  const hasData = slot.data && slot.data.length > 0;

  const isSet = [
    hasTitle,
    hasDidakt,
    hasNote,
    hasHomework,
    hasLehrplanBezug,
    hasData,
  ].some((a) => a);
  return !isSet;
}

export function moveDayslots(
  data: { sourceIndex: number; targetIndex: number },
  timeslots: Slot[],
  slotdays: Slotday[],
) {
  // search source index and get data
  const { sourceIndex, targetIndex } = data;

  // store ids and really later because they are bound to the date
  const ids = timeslots.map((slot) => slot.id);
  const rearrangedTimeslots = rearrangeTimeslots(
    timeslots,
    slotdays,
    sourceIndex,
    targetIndex,
  );

  const newTimeslots = rearrangedTimeslots.map((slot, index) => ({
    ...slot,
    id: ids[index],
    idx: index,
  }));

  const newSlotdays = prepareCalendarDataFromTimeslots(slotdays, newTimeslots);

  return {
    timeslots: newTimeslots,
    slotdays: newSlotdays,
  };
}

/*
 * The slots should behave like blocks
 * - where the leftmost block is the starting point
 * - all other slots float to the left filling vacant spots when something is moved
 * - holidays are treated as if they are not there
 */
function rearrangeTimeslots(
  timeslots: Slot[],
  slotdays: Slotday[],
  sourceIndex: number,
  targetIndex: number,
) {
  // remove holdays
  const timeslotsWithoutHolidays = timeslots.filter((_, index) => {
    // filter out timeslots which do not fit into the sequence anymore
    // also check if the day is not a holiday
    const day = slotdays[index];
    return day && !day.holiday;
  });

  // adjust source and targetIndex for holdays (minus # of holidays before it)
  const adjustedSource = translateIndex(sourceIndex, slotdays);
  const adjustedTarget = translateIndex(targetIndex, slotdays);

  const newTimeslots = moveItems({
    source: adjustedSource,
    target: adjustedTarget,
    items: timeslotsWithoutHolidays,
    isPlanned: (item: Slot) => !isEmptyDaySlot(item),
  });

  // add back holidays into correct spots
  const timeslotsWithHolidays = slotdays.reduce((acc, day, index) => {
    const slot = timeslots[index];
    if (day.holiday && slot) {
      return acc.toSpliced(index, 0, slot);
    }
    return acc;
  }, newTimeslots);

  // add back extra timeslots which are outside of the sequence days
  return [...timeslotsWithHolidays, ...timeslots.slice(slotdays.length)];
}

// since we need to ignore holidays for the moving calculation
// we need to adjust the indices for the removed holidays
function translateIndex(index: number, slotdays: Slotday[]): number {
  return slotdays.reduce(
    (count, item, idx) => (!!item.holiday && idx < index ? count - 1 : count),
    index,
  );
}
