import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";

const sizerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  visibility: "hidden",
  height: 0,
  overflow: "scroll",
  whiteSpace: "pre",
};

const textWidthAffectingStyleProps = [
  "font-size",
  "font-family",
  "font-weight",
  "font-style",
  "letter-spacing",
  "text-transform",
  "box-sizing",
  "padding-left",
  "padding-right",
  "border-right-width",
  "border-left-width",
];

/*
 * Size an input based on measuring a hidden <div> and applying that size
 * to the input.
 * Adapted from https://github.com/JedWatson/react-input-autosize/blob/master/src/AutosizeInput.js
 * which is not updated for React 18 (as of Auf 24 2023)
 * */
const AutosizeInput = forwardRef((props, ref) => {
  const { value = "", placeholder, minWidth = 100, ...restProps } = props;
  const [inputWidth, setInputWidth] = useState(minWidth);
  const sizerRef = useRef(null);

  // share reference for forwardedRef and local ref for input used in this component
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  useEffect(() => {
    // copy over input styles that might affect sizer width
    const inputStyles = window.getComputedStyle(inputRef.current);
    textWidthAffectingStyleProps.forEach((styleProp) => {
      const val = inputStyles.getPropertyValue(styleProp);
      sizerRef.current.style.setProperty(styleProp, val);
    });

    // measure the sizer element and set width on input
    const newInputWidth = sizerRef.current.scrollWidth + 2;
    setInputWidth(newInputWidth);
  }, [value, placeholder]);

  return (
    <>
      <input
        value={value}
        placeholder={placeholder}
        style={{ width: inputWidth }}
        ref={inputRef}
        {...restProps}
      />
      <div ref={sizerRef} style={sizerStyle}>
        {value.length > 0 ? value : placeholder}
      </div>
    </>
  );
});

export { AutosizeInput };
