import { createSelector } from "@reduxjs/toolkit";
import * as CONST from "../../common/constants/Components";
import {
  getWeekNummer2PositionMap,
  getYearScooldDays,
} from "../../common/components/sequence_calendar/Utils";
import { selectCurrentSchoolyear } from "../current-schoolyear/current-schoolyear-slice";
import { selectGetHolidaysData, selectGetSchoolData } from "../../services/api";

// SELECTORS
export function selectSchoolRegion(state) {
  return selectGetSchoolData(state, selectCurrentSchoolyear(state)).region;
}

export const selectSchoolWithHolidays = createSelector(
  [
    (state) => selectGetSchoolData(state, selectCurrentSchoolyear(state)),
    (state) =>
      selectGetHolidaysData(state, {
        region: selectSchoolRegion(state),
        schoolyear: selectCurrentSchoolyear(state),
      }),
  ],
  (schoolData, holidayData) => {
    const {
      start: startDate,
      end: endDate,
      holidays,
      id,
      modified,
      modifiedPublicHolidays,
      modifiedHolidays,
      label,
    } = holidayData;

    return {
      ...schoolData,
      startDate,
      endDate,
      holidays,
      modified,
      modifiedPublicHolidays,
      modifiedHolidays,
      schoolYear: id,
      schoolyearLabel: label,
      calendarMap: getWeekNummer2PositionMap(
        startDate,
        endDate,
        CONST.CALENDAR_WEEK_UNIT,
        holidays,
      ),
      schoolYearDays: getYearScooldDays(
        startDate,
        endDate,
        CONST.CALENDAR_WEEK_UNIT,
      ),
    };
  },
);
