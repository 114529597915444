import React from "react";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import FGEditor from "../../../../common/components/sequences/FGEditor";
import PdfArtikulation from "../../../../common/pdf/PDFArtikulation";
import * as ACTIONS from "../../../../features/sequence/actions";
import {
  selectCurrentSchoolyearData,
  selectCurrentSchoolyear,
} from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { selectGetSchoolData } from "../../../../services/api";

const mapStateToProps = (state) => ({
  dayslotid: state.sequences.dayslotid,
  editor_idx: state.sequences.editor_idx,
  active_sequence: state.sequences.active_sequence_item,
  timeslots: state.sequences.timeslots,
  current_schoolyear: selectCurrentSchoolyearData(state),
  dayslot_didakt: state.sequences.dayslot_didakt,
  dayslot_homework: state.sequences.dayslot_homework,
  active_sequence_cluster: state.sequences.sequenceCluster,
  taglist: state.taglist,
  dayslot_lehrplanbezug: state.sequences.dayslot_lehrplanbezug,
  school: selectGetSchoolData(state, selectCurrentSchoolyear(state)),
  authentication: state.authentication,
  dayslotdate: state.sequences.dayslotdate,
  dayslot_title: state.sequences.dayslot_title,
  active_slot_idx: state.sequences.active_slot_idx,
  active_timeslot: state.sequences.active_timeslot,
  dayslots: state.sequences.slotdays, // zeitblöcke
});

const mapDispatchToProps = {
  updateTimeSlots: ACTIONS.updateTimeSlots,
  changeDidaktHinter: ACTIONS.changeDidaktHinter,
  changeLehrplanbezug: ACTIONS.changeLehrplanbezug,
  changeTeachTools: ACTIONS.changeTeachTools,
  changeHomework: ACTIONS.changeHomework,
};

class SidebarArticulationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.updateItems = this.updateItems.bind(this);
    this.updateLehrplanbezug = this.updateLehrplanbezug.bind(this);
    this.printArtikulationsPdf = this.printArtikulationsPdf.bind(this);
  }

  /**
   * Artikulationspdf
   */
  printArtikulationsPdf(material_list) {
    PdfArtikulation(
      this.props.school,
      this.props.authentication,
      this.props.active_sequence_cluster,
      {
        date: this.props.dayslotdate,
        title: this.props.dayslot_title,
        didaktik: this.props.dayslot_didakt,
        lehrplanbezug: this.props.dayslot_lehrplanbezug,
        sequence_title: this.props.active_sequence.title,
        homework: this.props.dayslot_homework,
        material_list,
        current_hour: this.props.active_slot_idx,
        id: this.props.dayslotid,
      },
      this.props.active_timeslot,
      this.props.dayslots,
      this.props.current_schoolyear,
    );
  }

  /**
   * update lehrplanbezug
   */
  updateLehrplanbezug(value) {
    this.props.changeLehrplanbezug(value);
  }

  updateItems() {
    this.props.updateTimeSlots();
  }

  render() {
    const material_preselect =
      this.props.taglist.material_tags &&
      this.props.taglist.material_tags.length > 0
        ? this.props.taglist.material_tags
        : [];
    const _material_filter = [];
    if (
      this.props.taglist.material_tags &&
      this.props.taglist.material_tags.length > 0
    ) {
      this.props.taglist.material_tags.forEach((elem) => {
        _material_filter[elem.label] = elem.label;
      });
    }

    const material_list_for_day = [];
    const _material_list_for_day = [];

    const lehrplanbezug_list = [];
    const lehrplansequence = [];
    const __lehrplanbezug_list = [];
    const seq_lehrplanbezug_list = [];

    const lp_key = String(this.props.active_sequence_cluster._id);
    if (
      this.props.taglist.lehrplanbezug_tags &&
      this.props.taglist.lehrplanbezug_tags[lp_key]
    ) {
      const tmp_list = this.props.taglist.lehrplanbezug_tags[lp_key];

      tmp_list.forEach((elem) => {
        __lehrplanbezug_list[elem.label] = elem.label;

        lehrplanbezug_list.push({
          label: elem.label,
          value: elem.value,
        });
      });
    }

    if (this.props.timeslots && this.props.timeslots.length > 0) {
      this.props.timeslots.forEach((elem) => {
        if (elem.data && elem.data.length > 0) {
          elem.data.forEach((data) => {
            if (data.material && data.material.length > 0) {
              data.material.forEach((material) => {
                if (!_material_filter[material.value]) {
                  _material_filter[material.value] = material.value;
                  material_preselect.push({
                    label: material.label,
                    value: material.value,
                  });
                }
                if (Number(elem.id) === Number(this.props.dayslotid)) {
                  if (!_material_list_for_day[material.value]) {
                    _material_list_for_day[material.value] = material.value;
                    material_list_for_day.push({
                      label: material.label,
                      value: material.value,
                    });
                  }
                }
              });
            }
          });
        }
        if (elem.lehrplanbezug && elem.lehrplanbezug.length > 0) {
          elem.lehrplanbezug.forEach((lb) => {
            if (!__lehrplanbezug_list[lb.value]) {
              __lehrplanbezug_list[lb.value] = lb.value;
              lehrplanbezug_list.push({
                label: lb.label,
                value: lb.value,
              });
            }
            if (!seq_lehrplanbezug_list[lb.value]) {
              seq_lehrplanbezug_list[lb.value] = lb.value;
              lehrplansequence.push({
                label: lb.label,
                value: lb.value,
              });
            }
          });
        }
      });
    }

    const idx_update_key = `${this.props.dayslotid}-${this.props.editor_idx}`;

    let { dayslot_didakt } = this.props;
    if (!dayslot_didakt) {
      dayslot_didakt = "";
    }

    let text_homework = this.props.dayslot_homework;
    if (text_homework === "<p><br></p>") {
      text_homework = "";
    }

    return (
      <div className="sidebar attachments-wrapper">
        <div className="section didakt-hinter">
          <div className="title">Didaktischer Hintergrund</div>
          <div className="text">
            <FGEditor
              key={`didkat-hinter-dayslotid-${idx_update_key}`}
              placeholder="Hier hinklicken um Informationen zum didaktischen Hintergrund einzutragen"
              value={dayslot_didakt}
              onChange={(e) => {
                this.props.changeDidaktHinter(1, e);
              }}
              onBlurCall={this.updateItems}
            />
          </div>
        </div>

        <div className="section">
          <div className="title">Hausaufgaben</div>
          <div className="text">
            <FGEditor
              key={`homework_editor-${idx_update_key}`}
              placeholder="Hier hinklicken um Hausaufgaben einzutragen"
              value={text_homework}
              onChange={(e) => this.props.changeHomework(e)}
              onBlurCall={() => this.updateItems("clear_homework_editor")}
              // promptText='test'
            />
          </div>
        </div>

        <div className="section">
          <div className="title">Lehrplanbezug</div>
          <div className="text css-1rtrksz">
            <CreatableSelect
              key={`seq-lp-${idx_update_key}`}
              isMulti
              onChange={this.updateLehrplanbezug}
              options={lehrplanbezug_list}
              placeholder="Hier hinklicken um den Lehrplanbezug einzutragen"
              className="cleanSelect"
              classNamePrefix="react-select"
              onBlur={() => this.updateItems()}
              value={this.props.dayslot_lehrplanbezug}
              promptText="My custom prompt"
            />
          </div>
        </div>

        <div className="section">
          <div className="title readonly">Gefundenes Material</div>
          <div className="text css-1rtrksz">
            {material_list_for_day.map((elem, idx) => (
              <div key={`tag-${idx}`} className="css-xwjg1b">
                <div className="css-10odw4q">{elem.label}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="section">
          <div className="flex items-center">
            <img
              className="inline-block mr-2"
              src="/assets/images/pdf-icon.png"
            />
            <a
              className="font-bold"
              href="#"
              onClick={() => {
                this.printArtikulationsPdf(material_list_for_day);
              }}
            >
              Artikulationsschema herunterladen
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarArticulationComponent);
