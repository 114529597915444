import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import { createTags } from "../taglist/actions";

export const GET_SEQUENCE_ITEMS = "GET_SEQUENCE_ITEMS";
export const SAVE_SEQUENCE_ITEMS = "SAVE_SEQUENCE_ITEMS";
export const STAMP_ITEM = "STAMP_ITEM";

export function getSequenceItems(schoolyear) {
  return (dispatch) =>
    fetch(
      `${URLS.API_SEQUENCE_ITEMS_URL_INIT}/__REDUNDANT_PATH__/${schoolyear}`,
      {
        method: "get",
        headers: { Authorization: `bearer ${Auth.getToken()}` },
      },
    ).then((response) => {
      if (response.ok) {
        response.json().then((seqItemData) => {
          dispatch({
            type: GET_SEQUENCE_ITEMS,
            payload: seqItemData,
          });
          dispatch(createTags(seqItemData));
        });
      } else {
        response.json().then(() => {
          dispatch({
            type: GET_SEQUENCE_ITEMS,
            payload: [],
          });
        });
      }
    });
}

export function updateSequenceItems(items, schoolyear) {
  const user_id = Auth.getUserId();
  const payload = {
    sequence_items: items,
    user_id,
    schoolyear,
  };

  return (dispatch) => {
    dispatch({
      type: SAVE_SEQUENCE_ITEMS,
      sequence_items: items,
    });

    return fetch(URLS.API_SEQUENCE_ITEMS_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: SAVE_SEQUENCE_ITEMS,
            sequence_items: data.item.sequence_items,
          });
        });
      } else {
        response.json().then(() => {
          dispatch({
            type: SAVE_SEQUENCE_ITEMS,
            sequence_items: "",
          });
        });
      }
    });
  };
}

/**
 * update from template usage
 * @param items
 * @param schoolyear
 * @returns {function(*=): Promise<T>}
 */
export function updateSequenceItemsFromTemplate(
  items,
  sequences,
  schoolyear,
  templateId,
) {
  const user_id = Auth.getUserId();
  const payload = {
    sequence_items: items,
    user_id,
    schoolyear,
    sequences,
    templateId,
  };
  return (dispatch) =>
    fetch(URLS.API_SEQUENCE_ITEMS_FROM_TEMPLATE, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: SAVE_SEQUENCE_ITEMS,
            sequence_items: data.item.sequence_items,
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: SAVE_SEQUENCE_ITEMS,
            sequence_items: "",
            message: error,
          });
        });
      }
    });
}

export function stampClusterItem(cluster) {
  return (dispatch) =>
    dispatch({
      type: STAMP_ITEM,
      cluster,
    });
}
