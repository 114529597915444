import React from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import FGEditor from "../../../../common/components/sequences/FGEditor";
import PdfSequence from "../../../../common/pdf/PdfSequence";
import ArrowBack from "../../../../assets/images/svg/pfeil-zurueck.svg";
import * as ACTIONS from "../../../../features/sequence/actions";
import {
  selectCurrentSchoolyearData,
  selectCurrentSchoolyear,
} from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { selectGetSchoolData } from "../../../../services/api";

const mapStateToProps = (state) => ({
  active_sequence: state.sequences.active_sequence_item,
  active_sequence_cluster: state.sequences.sequenceCluster,
  active_sequence_didakt: state.sequences.active_sequence_didakt,
  timeslots: state.sequences.timeslots,
  school: selectGetSchoolData(state, selectCurrentSchoolyear(state)),
  current_schoolyear: selectCurrentSchoolyearData(state),
  taglist: state.taglist,
  dayslot_lehrplanbezug: state.sequences.dayslot_lehrplanbezug,
  timetable: state.timetable,
  authentication: state.authentication,
  editor_idx: state.sequences.editor_idx,
  dayslotid: state.sequences.dayslotid,
});
const mapDispatchToProps = {
  updateSequenceItemDitakt: ACTIONS.changeSequenceItemDiktat,
  updateSequenceItemData: ACTIONS.updateSequenceItemData,
};

const INPUT_DATE_FORMAT = "yMMdd";

class SequenceMetaHeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_details: false,
    };
    this.toggleDetails = this.toggleDetails.bind(this);
    this.updateSequenceItemDitakt = this.updateSequenceItemDitakt.bind(this);
    this.updateSequenceItemsForDiktat =
      this.updateSequenceItemsForDiktat.bind(this);
    this.printSequencePdf = this.printSequencePdf.bind(this);
  }

  /**
   * sequence pdf
   */
  printSequencePdf() {
    const { calc_date, calc_end_date } = this.props.active_sequence;
    const display_start_date = DateTime.fromFormat(
      calc_date,
      INPUT_DATE_FORMAT,
    ).toLocaleString({
      day: "numeric",
      month: "long",
    });
    const display_end_date = DateTime.fromFormat(
      calc_end_date,
      INPUT_DATE_FORMAT,
    ).toLocaleString({
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const display_date = `${display_start_date} bis ${display_end_date}`;

    const seq_didakt_text =
      (!this.props.active_sequence_didakt ||
        this.props.active_sequence_didakt === "<p><br></p>") &&
      this.props.active_sequence_cluster.diktat
        ? this.props.active_sequence_cluster.diktat
        : this.props.active_sequence_didakt;

    PdfSequence(
      this.props.school,
      this.props.authentication,
      this.props.active_sequence_cluster,
      {
        date: this.props.dayslotdate,
        display_date,
        title: this.props.dayslot_title,
        didaktik: seq_didakt_text,
        // didaktik: this.props.dayslot_didakt,
        lehrplanbezug: this.props.dayslot_lehrplanbezug,
        sequence_title: this.props.active_sequence.title,
        saturday: this.props.timetable.saturday,
      },
      this.props.timeslots,
      this.props.active_sequence.calc_end_date,
      this.props.current_schoolyear,
    );
  }

  /**
   * update text via redux
   */
  updateSequenceItemDitakt(value) {
    this.props.updateSequenceItemDitakt(value);
  }

  /**
   * update articulation text from heade
   */
  updateSequenceItemsForDiktat() {
    const newItem = {
      ...this.props.active_sequence,
      didakt: this.props.active_sequence_didakt,
    };
    this.props.updateSequenceItemData(this.props.active_sequence.id, newItem);
  }

  toggleDetails() {
    const new_state = !this.state.show_details;
    this.setState({
      show_details: new_state,
    });
  }

  render() {
    const idx_update_key = `${this.props.dayslotid}-${this.props.editor_idx}`;

    const { title, calc_date, calc_end_date } = this.props.active_sequence;

    const display_start_date = calc_date
      ? DateTime.fromFormat(calc_date, INPUT_DATE_FORMAT).toLocaleString({
          day: "numeric",
          month: "long",
        })
      : "";
    const display_end_date = calc_end_date
      ? DateTime.fromFormat(calc_end_date, INPUT_DATE_FORMAT).toLocaleString({
          day: "numeric",
          month: "long",
        })
      : "";
    const weeks = this.props.active_sequence.label_weeks
      ? this.props.active_sequence.label_weeks
      : this.props.active_sequence.weeks;
    const hours = this.props.active_sequence.label_hours
      ? this.props.active_sequence.label_hours
      : this.props.active_sequence.hours;

    // auslagern
    let show_detail_text = "Details einblenden";
    const show_details = this.state.show_details ? " show " : "";
    if (show_details === " show ") {
      show_detail_text = "Details ausblenden";
    }
    const seq_didakt_text =
      (!this.props.active_sequence_didakt ||
        this.props.active_sequence_didakt === "<p><br></p>") &&
      this.props.active_sequence_cluster.diktat
        ? this.props.active_sequence_cluster.diktat
        : this.props.active_sequence_didakt;

    const lehrplanbezug_list = [];
    const lehrplansequence = [];
    const __lehrplanbezug_list = [];
    const seq_lehrplanbezug_list = [];

    // var lp_key = String(this.props.active_sequence_cluster._id) + String(this.props.active_sequence_cluster.classroom_id);
    const lp_key = String(this.props.active_sequence_cluster._id);
    if (
      this.props.taglist.lehrplanbezug_tags &&
      this.props.taglist.lehrplanbezug_tags[lp_key]
    ) {
      const tmp_list = this.props.taglist.lehrplanbezug_tags[lp_key];

      tmp_list.forEach((elem) => {
        __lehrplanbezug_list[elem.label] = elem.label;

        lehrplanbezug_list.push({
          label: elem.label,
          value: elem.value,
        });
      });
    }

    if (this.props.timeslots && this.props.timeslots.length > 0) {
      this.props.timeslots.forEach((elem) => {
        if (elem.lehrplanbezug && elem.lehrplanbezug.length > 0) {
          elem.lehrplanbezug.forEach((lb) => {
            if (!__lehrplanbezug_list[lb.value]) {
              __lehrplanbezug_list[lb.value] = lb.value;
              lehrplanbezug_list.push({
                label: lb.label,
                value: lb.value,
              });
            }
            if (!seq_lehrplanbezug_list[lb.value]) {
              seq_lehrplanbezug_list[lb.value] = lb.value;
              lehrplansequence.push({
                label: lb.label,
                value: lb.value,
              });
            }
          });
        }
      });
    }

    return (
      <div className="sequence-meta-header-wrapper">
        <div className="meta-wrapper">
          <div className="relative">
            <div
              className="flex items-center box-border absolute top-1 transform -translate-x-full font-bold mr-2.5 h-[35px]
                  after:border-0 after:border-r-2 after:border-solid after:border-[#afafaf] after:h-full after:content-[''] after:block"
            >
              <Link
                to="/zeitleiste"
                className="flex items-center h-full pr-2.5 "
              >
                <ArrowBack width="0.5rem" />
              </Link>
            </div>
            <div className="ml-2.5 inline-block">
              <h1>{title}</h1>
              <div className="meta">
                <a>{display_start_date}</a> - <a>{display_end_date}</a>
                <span className="inline-block pl-2">
                  {" "}
                  | {weeks} Wochen / {hours} Stunden{" "}
                </span>
                <span className="inline-block pl-2">
                  {" "}
                  |{" "}
                  <a className="anchor" onClick={this.toggleDetails}>
                    {show_detail_text}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }} />
        </div>

        <div className={`details_wrapper ${show_details}`}>
          <div className="pdf_download">
            <span className="icon-wrapper">
              <img src="/assets/images/pdf-icon.png" className="icon" />
            </span>
            <span className="text-wrapper">
              <a href="#" onClick={this.printSequencePdf}>
                Sequenzplan herunterladen
              </a>
            </span>
          </div>
          <div className="didakt_wrapper">
            <div className="editor-wrapper">
              <div className="section">
                <div className="title">Didaktischer Hintergrund (Sequenz)</div>
                <div className="text">
                  <FGEditor
                    key={`seq_ditakt_editor-${idx_update_key}`}
                    placeholder="Hier hinklicken um Informationen zum didaktischen Hintergrund der Sequenz einzutragen"
                    value={seq_didakt_text}
                    onChange={(e) => {
                      this.updateSequenceItemDitakt(e);
                    }}
                    onBlurCall={() => this.updateSequenceItemsForDiktat()}
                  />
                </div>
              </div>
            </div>
            <div className="tags_wrapper">
              <div className="section">
                <div className="title readonly">Gefundener Lehrplanbezug</div>
                <div className="text css-1rtrksz">
                  {lehrplansequence.map((elem, idx) => (
                    <div key={`tag-${idx}`} className="css-xwjg1b">
                      <div className="css-10odw4q">{elem.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SequenceMetaHeaderComponent);
