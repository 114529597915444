import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Draggable, {
  type Movement,
} from "../../../common/components/generic/Draggable";
import { classList } from "../../../common/utils/classList";
import { type Slot, type Slotday } from "../../../features/sequence/types";
import { sessionClipboard } from "../../../services/session-clipboard";
import { featureFlags } from "../../feature-flags";

const slotWidth = 68;

type SlotWithHasMaterials = Slot & { hasMaterials: boolean };

const emptyHTMLString = "<p><br></p>";
function isSlotPlanned(slot: Slot & { hasMaterials: boolean }): boolean {
  const { title, didakt, homework, note, lehrplanbezug, data, hasMaterials } =
    slot;

  return [
    title !== "",
    didakt && didakt !== "" && didakt !== emptyHTMLString,
    homework !== "" && homework !== emptyHTMLString,
    note && note !== "" && note !== emptyHTMLString,
    lehrplanbezug && lehrplanbezug.length > 0,
    data && data.length > 0,
    hasMaterials,
  ].some((val) => val);
}

function Circles(props: {
  count: number;
  circleAlert: boolean;
  isSelected: boolean;
}) {
  return (
    <ul className="ml-1 relative h-[9px]">
      {Array.from({ length: props.count }, (_, index) => (
        <li
          className={classList({
            "absolute rounded-full aspect-square w-[9px] border-2 border-gray-100":
              true,
            "bg-gray-80": !props.circleAlert && !props.isSelected,
            "bg-green": !props.circleAlert && props.isSelected,
            "bg-red": props.circleAlert,
          })}
          key={index}
          style={{ left: `${5 * index}px` }}
        />
      ))}
    </ul>
  );
}

function HoverTriangle(props: {
  mode: "copy" | "paste";
  isSelected: boolean;
  onClick: (event: React.MouseEvent) => void;
}) {
  const modeSpecificStyles =
    props.mode === "copy"
      ? {
          clipPath: "polygon(100% 100%, 0 100%, 100% 0)",
          icon: "C",
        }
      : {
          clipPath: "polygon(0 0, 0 100%, 100% 0)",
          icon: "P",
        };
  return (
    <div
      className={classList({
        "absolute w-[29px] aspect-square transition-colors": true,
        "bottom-0 right-0": props.mode === "copy",
        "top-0 left-0": props.mode === "paste",
        "bg-gray-30 hover:bg-green": !props.isSelected,
        "bg-green hover:bg-gray-30": props.isSelected,
      })}
      style={{
        ...modeSpecificStyles,
      }}
      onClick={props.onClick}
    >
      {modeSpecificStyles.icon}
    </div>
  );
}

type Props = {
  activeSlotIdx: number;
  daylist: Array<Slotday>;
  timeslots: Array<SlotWithHasMaterials>;
  lessonDuration: number;
  onSlotClick: (
    day_id: string,
    slot_duration: number,
    dayslotdate: string,
    hours_text: string,
    clicked_index: number,
  ) => void;
  onSlotMove: (indices: { sourceIndex: number; targetIndex: number }) => void;
};

type State = {
  hoverIdx: "empty" | number;
  dragging: boolean;
  isDraggedToNewPosition: boolean;
};

function DaySlots(props: Props) {
  const [state, setState] = useState<State>({
    hoverIdx: "empty",
    dragging: false,
    isDraggedToNewPosition: false,
  });

  const { pathname } = useLocation();

  const handleHoverEnter = (id: number) => {
    if (!state.dragging) {
      setState({ ...state, hoverIdx: id });
    }
  };

  const handleHoverOut = () => {
    if (!state.dragging) {
      setState({ ...state, hoverIdx: "empty" });
    }
  };

  const handleSlotClick = (
    e: React.MouseEvent,
    day_id: string,
    clicked_index: number,
    slot_duration: number,
    dayslotdate: string,
    hours_text: string,
  ) => {
    if (state.isDraggedToNewPosition) {
      e.preventDefault();
      setState({ ...state, isDraggedToNewPosition: false });
      return;
    }

    // update State
    props.onSlotClick(
      day_id,
      slot_duration,
      dayslotdate,
      hours_text,
      clicked_index,
    );
  };

  /**
   * tmp id ist das element welches verschoben wird
   * @param e
   * @param movement
   * @param sourceIndex
   */
  const handleDragStopDayslot = (movement: Movement, sourceIndex: number) => {
    const isDragged = movement.x >= 50 || movement.x <= -55;
    setState({ ...state, dragging: false, isDraggedToNewPosition: isDragged });

    if (isDragged) {
      // only if element dragged - not clicked
      const roundedPosition = Math.round(movement.x / 78);

      const targetIndex = sourceIndex + roundedPosition;
      // IDX ist das element auf das verschoben werden möchte.
      // get dragged target element and check ready to use
      const targetElem = props.daylist[targetIndex];

      // bail out if not dropped on other element or target is holiday
      if (!targetElem || targetElem.holiday_duration > 0) return;

      // move slots with exists dat
      props.onSlotMove({
        sourceIndex,
        targetIndex,
      });
    }
  };

  const handleDragStartDayslot = () => {
    setState({ ...state, dragging: true });
  };

  const firstNonHoliday = props.daylist.findIndex(
    (day) => !(day.holiday_duration > 0),
  );
  const defaultFirstDayslot = props.activeSlotIdx || firstNonHoliday;

  // Access session clipboard via RTK Query
  const [setLessonTrigger] = sessionClipboard.useSetLessonMutation();
  const { data: copiedLesson } = sessionClipboard.useGetLessonQuery(undefined);

  return (
    <div className="overflow-x-auto">
      <ul id="day-slots-wrapper" className="flex gap-px w-fit">
        {props.daylist.map((day, i) => {
          const isHolidaySlot = day.holiday_duration > 0;
          const day_id = isHolidaySlot ? "" : day.day_id;

          // find data list in timeslots for duration checks
          const timeslot = props.timeslots.find((x) => x.id === day.day_id);
          const isPlanned = timeslot ? isSlotPlanned(timeslot) : false;
          const isHover = state.hoverIdx === i;
          const isDragging = isHover && state.dragging;

          const real_duration =
            timeslot && timeslot.data
              ? timeslot.data.reduce(
                  (sum, elem) => sum + Number(elem.duration),
                  0,
                )
              : 0;

          const duration_count = day.slot_duration / props.lessonDuration;

          // hover move stuff

          const isSelected = i === defaultFirstDayslot;
          const disableDragging = day.holiday !== "";

          if (isHolidaySlot) {
            return (
              <li
                key={`day-slot-${i}`}
                style={{
                  width: day.holiday_duration * slotWidth + 8,
                }}
                className="flex-none p-2 bg-gray-20 text-gray-100 text-[13px] relative select-none flex flex-col justify-between"
              >
                <div>{day.holiday_title}</div>
                <div className="mb-[5px] text-[13px] leading-[15.25px]">
                  <time className="font-bold">{day.day}</time>
                  <div className="date">{day.short}</div>
                </div>
              </li>
            );
          }

          return (
            <li
              key={`day-slot-${i}`}
              className={classList({
                "flex-none bg-gray-100 cursor-pointer relative": true,
                "active no_hover_move": isSelected,
                "after:absolute after:h-[3px] after:bg-green after:inset-x-0 after:bottom-0":
                  isSelected,
                "z-50": isHover,
              })}
              onMouseLeave={() => handleHoverOut()}
              onMouseEnter={() => handleHoverEnter(i)}
            >
              <Draggable
                onDragStart={handleDragStartDayslot}
                onDragStop={(_, movement) => {
                  handleDragStopDayslot(movement, i);
                }}
                bounds="day-slots-wrapper"
                disableDragging={disableDragging}
              >
                <Link
                  onClick={(e) => {
                    if (!state.isDraggedToNewPosition) {
                      handleSlotClick(
                        e,
                        day_id,
                        i,
                        day.slot_duration,
                        day.dayslotdate,
                        day.hours_text,
                      );
                    }
                  }}
                  className={classList({
                    "block select-none p-2 pb-0 h-[123px]": true,
                    "cursor-move": !disableDragging,
                  })}
                  to={`${pathname}?ueId=${day.ueId}`}
                  draggable="false"
                  replace
                >
                  <div
                    className={classList({
                      "relative w-15 h-17 border-2 rounded px-1.5 py-1 cursor-pointer overflow-hidden text-2xs font-bold":
                        true,
                      "bg-gray-80": !isPlanned,
                      "bg-gray-100": isPlanned,
                      "border-gray-80": !isSelected && !isHover,
                      "border-gray-30": !isSelected && isHover,
                      "border-green": isSelected,
                      "shadow-lg": isDragging,
                    })}
                  >
                    {featureFlags.showLessonCopyPaste() && isHover ? (
                      <HoverTriangle
                        mode={isPlanned ? "copy" : "paste"}
                        isSelected={isSelected}
                        onClick={() => {
                          if (isPlanned) {
                            // copy lesson into clipboard
                            console.log("COPY INTO CLIPBOARD", timeslot);
                            setLessonTrigger(timeslot);
                            return;
                          }
                          // paste it to the correct position
                          console.log("PASTE FROM CLIPBOARD", copiedLesson);
                        }}
                      />
                    ) : null}
                    {day.title}
                  </div>
                </Link>
              </Draggable>

              <div className="select-none absolute px-2 bottom-[13px] text-[13px] leading-[15.25px] text-gray-30">
                <div className="flex items-center">
                  <time className="font-bold">{day.day}</time>
                  <Circles
                    count={duration_count}
                    isSelected={isSelected}
                    circleAlert={real_duration > day.slot_duration}
                  />
                </div>
                <div className="date">{day.short}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DaySlots;
