import * as TACTIONS from "./actions";

const taglistStore = [];
export function taglist(state = taglistStore, action) {
  switch (action.type) {
    case TACTIONS.SET_TAGLIST: {
      const new_state = {
        ...state,
        material_tags: action.material_tags,
        lehrplanbezug_tags: action.lehrplanbezug_tags,
      };
      return new_state;
    }
    default:
      return state;
  }
}
