import React from "react";
import Dropdown from "react-dropdown";
import Modal from "react-modal";
import { connect } from "react-redux";
import { selectSequenceTemplatesWithUniqueLists } from "../../../features/templates/templates-slice";
import { api } from "../../../services/api";

import FilterTagList from "./FilterTagList";
import CreateTemplate from "./CreateTemplate";
import ScreenNote from "../../../common/components/ScreenNotes";
import { selectCurrentSchoolyearData } from "../../../features/current-schoolyear/current-schoolyear-slice";

const mapStateToProps = (state) => ({
  current_schoolyear: selectCurrentSchoolyearData(state),
  subjects: state.subjects,
  seq_template_list: selectSequenceTemplatesWithUniqueLists(state),
});

const mapDispatchToProps = {
  getTemplateList: api.endpoints.getSequenceTemplates.initiate,
  restoreTemplateItem: api.endpoints.restoreSequenceTemplate.initiate,
  deleteTemplate: api.endpoints.deleteSequenceTemplate.initiate,
};

class TemplateListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_subject: {
        label: "Alle Fächer",
        value: "",
      },
      selected_grade: {
        label: "Alle Jahrgangsstufen",
        value: "",
      },
      subject_filter: false,
      tags_filter: false,
      grade_filter: false,
      show_update_modal: false,
      update_item: false,
      show_update_success_note: false,
      update_title: "",
      deleted_object: {},
      show_success_delete: false,
    };
    this.render_list = this.render_list.bind(this);
    this.filterSchoolClass = this.filterSchoolClass.bind(this);
    this.filterGrade = this.filterGrade.bind(this);
    this.filterTags = this.filterTags.bind(this);
    this.renderOverlayUpdateTemplate =
      this.renderOverlayUpdateTemplate.bind(this);
    this.showUpdateModal = this.showUpdateModal.bind(this);
    this.closeUpdateModal = this.closeUpdateModal.bind(this);
    this.renderSuccessUpdateNote = this.renderSuccessUpdateNote.bind(this);
    this.closeSuccessUpdateNote = this.closeSuccessUpdateNote.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.showSuccessDeleteNote = this.showSuccessDeleteNote.bind(this);
    this.closeSuccessDeleteNote = this.closeSuccessDeleteNote.bind(this);
    this.undoDeleteItem = this.undoDeleteItem.bind(this);
  }

  componentDidMount() {
    /**
     * remove blur if exists
     */
    document.getElementById("content-container").classList.remove("isblured");
    const element = document.getElementById("loading-spinner");
    element.classList.remove("running");

    document.title = "Freigeist | Vorlagen";
    document.getElementById("backlink_bar").classList.add("template_list");

    const { unsubscribe } = this.props.getTemplateList();
    this.unsubscribe = unsubscribe;
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  deleteTemplate(item) {
    this.setState({
      deleted_object: { id: item._id, title: item.title },
      show_success_delete: true,
    });
    this.props.deleteTemplate(item._id);
  }

  filterSchoolClass(option) {
    this.setState({
      selected_subject: option,
      subject_filter: option.value,
    });
  }

  filterGrade(option) {
    this.setState({
      selected_grade: option,
      grade_filter: option.value,
    });
  }

  filterTags(tags) {
    this.setState({
      tags_filter: tags,
    });
  }

  closeUpdateModal(success_update, title) {
    document.getElementById("content-container").classList.remove("isblured");

    this.setState({
      show_update_modal: false,
      update_item: false,
      update_title: title,
      show_update_success_note: success_update,
    });
  }

  showUpdateModal(obj) {
    // console.log('show update modal now');
    this.setState({
      show_update_modal: true,
      update_item: obj,
    });
  }

  /**
   * close the error note layer
   */
  closeSuccessUpdateNote() {
    this.setState({
      show_update_success_note: false,
    });
  }

  /**
   * show success note
   * @returns {*}
   */
  showSuccessDeleteNote() {
    const text = `${this.state.deleted_object.title} wurde erfolgreich gelöscht`;
    const delete_key = `delete_note_${this.state.deleted_object.id}`;
    return (
      <ScreenNote
        key={delete_key}
        type="success_in"
        message={text}
        closeCallback={this.closeSuccessDeleteNote}
        undo
        functionCallbackUndo={this.undoDeleteItem}
      />
    );
  }

  /**
   * close the error note layer
   */
  closeSuccessDeleteNote() {
    this.setState({
      show_success_delete: false,
    });
  }

  undoDeleteItem() {
    // console.log('undo is calling');
    const { deleted_object } = this.state;
    this.setState({
      deleted_object: false,
      show_success_delete: false,
    });
    this.props.restoreTemplateItem(deleted_object.id);
  }

  /**
   * show success note
   * @returns {*}
   */
  renderSuccessUpdateNote(title) {
    const text = `${title} wurde erfolgreich aktualisiert`;
    return (
      <ScreenNote
        key="update_note"
        type="success"
        message={text}
        closeCallback={this.closeSuccessUpdateNote}
      />
    );
  }

  /**
   * show template overlay
   * @returns {*}
   */
  renderOverlayUpdateTemplate() {
    document.getElementById("content-container").classList.add("isblured");

    const { update_item } = this.state;
    const new_uodate_item = { ...update_item };

    return (
      <div className="modal-wrapper" id="create-template-modal-wrapper">
        <Modal
          isOpen={this.state.show_update_modal}
          onRequestClose={this.closeUpdateModal}
          ariaHideApp
          className="commonModal"
          overlayClassName="commonOverlay"
          contentLabel="Probezeit"
        >
          <div className="commonModalContent template-modal">
            <div className="closer">
              <a
                onClick={() => {
                  this.closeUpdateModal();
                }}
              >
                <img src="/assets/images/close_modal.png" />
              </a>
            </div>
            <CreateTemplate
              schoolyear={this.props.current_schoolyear.year}
              subjects={this.props.subjects.raw}
              selectedclass={new_uodate_item.selected_subject.value}
              selected_sequence={new_uodate_item}
              schoolyear_label={this.props.current_schoolyear.label}
              cancelTemplate={this.closeUpdateModal}
              makeUpdate
            />
          </div>
        </Modal>
      </div>
    );
  }

  /**
   * render list of other years
   * @param items
   * @returns {*}
   */
  render_list() {
    const { subject_filter, tags_filter, grade_filter } = this.state;
    // let filter_class_css;
    return (
      <div className="template-list-wrapper">
        {this.props.seq_template_list.list.map((item) => {
          let count_unit = 0;
          if (item.sequences_list) {
            if (
              item.sequences_list.timeslots &&
              item.sequences_list.timeslots.length > 0
            ) {
              item.sequences_list.timeslots.forEach((element) => {
                if (element.title) {
                  count_unit += 1;
                } else if (element.didakt !== "<p><br></p>" || element.didakt) {
                  count_unit += 1;
                } else if (
                  element.homework !== "<p><br></p>" ||
                  element.homework
                ) {
                  count_unit += 1;
                } else if (element.teach_tools) {
                  count_unit += 1;
                } else if (element.lehrplanbezug.length > 0) {
                  count_unit += 1;
                }
              });
            }
          }

          let show_item = true;
          let filter_class_css = "item";
          if (
            subject_filter &&
            subject_filter !== item.selected_subject.value
          ) {
            filter_class_css = "item hidden";
            show_item = false;
          }

          if (grade_filter && grade_filter !== item.selected_grade.value) {
            filter_class_css = "item hidden";
            show_item = false;
          }

          if (show_item && tags_filter.length > 0) {
            // console.log('filter with tags ---->');

            // tagsflter is set
            filter_class_css = "item hidden";
            tags_filter.forEach((ft) => {
              if (item.tags.indexOf(ft) > -1) {
                filter_class_css = "item";
              }
            });
          }

          return (
            <div className={filter_class_css} key={item.template_id}>
              <div className="item-wrapper">
                <div className="left">
                  <div className="title">{item.title}</div>
                  <div className="sub-title">
                    {count_unit} Unterrichtseinheiten
                  </div>
                  <div className="tags-wrapper">
                    <span className="info-tag tag">
                      {item.selected_subject.label}
                    </span>
                    <span className="info-tag tag">
                      Jahrgangsstufe {item.selected_grade.label}
                    </span>

                    {item.tags.map((tag) => (
                      <span key={`tag-${tag}`} className="tag">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="right">
                  <div className="actions">
                    <a
                      className="action_item"
                      href="#"
                      onClick={() => {
                        this.showUpdateModal(item);
                      }}
                    >
                      <img
                        src="/assets/images/edit-icon.png"
                        className="form-icon"
                      />
                    </a>
                    <a
                      className="action_item"
                      href="#"
                      onClick={() => {
                        this.deleteTemplate(item);
                      }}
                    >
                      <img
                        src="/assets/images/delete-icon.png"
                        className="form-icon"
                      />
                    </a>
                  </div>
                </div>
                <div style={{ clear: "both" }} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      selected_subject,
      selected_grade,
      show_update_modal,
      show_update_success_note,
      update_title,
      show_success_delete,
    } = this.state;
    const list_items = this.render_list();
    let subjects = [];
    subjects.push({
      label: "Alle Fächer",
      value: "",
    });
    subjects = subjects.concat(this.props.seq_template_list.subjects);

    let gradelist = [];
    gradelist.push({
      label: "Alle Jahrgangsstufen",
      value: "",
    });
    gradelist = gradelist.concat(this.props.seq_template_list.grades);

    const show_filters = this.props.seq_template_list.list.length !== 0;

    let update_modal;
    if (show_update_modal) {
      update_modal = this.renderOverlayUpdateTemplate();
    }

    let update_success_note;
    if (show_update_success_note) {
      update_success_note = this.renderSuccessUpdateNote(update_title);
    }

    let delete_succes_note;
    if (show_success_delete) {
      delete_succes_note = this.showSuccessDeleteNote();
    }

    return (
      <div id="sequence_templates" className="template-page">
        {update_modal}
        <div className="screen_note_wrapper">
          {update_success_note}
          {delete_succes_note}
        </div>

        <div className="content-wrapper">
          <div className="inner-wrapper">
            <div className="headline">
              <h2>Vorlagen Verwalten</h2>
            </div>
            {!show_filters && (
              <div className="message_no_content">
                Du hast noch keine Vorlagen erstellt. Sobald du Vorlagen
                angelegt hast, erscheinen sie hier.
              </div>
            )}
            {show_filters && (
              <div className="class_grade">
                <div className="dropdown-wrapper">
                  <div className="row">
                    <div className="field-group dropdowns">
                      <div className="col">
                        <label>Klasse</label>
                        <Dropdown
                          options={subjects}
                          onChange={this.filterSchoolClass}
                          value={selected_subject}
                          placeholder="Fach wählen"
                        />
                      </div>
                      <div className="col col-last">
                        <label>Jahrgangsstufe</label>
                        <Dropdown
                          options={gradelist}
                          onChange={this.filterGrade}
                          value={selected_grade}
                          placeholder="Jahrgangsstufe wählen"
                        />
                      </div>
                      <div style={{ clear: "both" }} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {show_filters && (
              <div className="tags_filter">
                <FilterTagList
                  items={this.props.seq_template_list.tags}
                  filterTags={this.filterTags}
                />
              </div>
            )}

            <div className="itemlist">{list_items}</div>
          </div>
        </div>
      </div>
    );
  }
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateListComponent);
