import * as ACTIONS from "./actions";
import { deepSet } from "../../common/utils/assortments";
import {
  sequenceMaterialSlice,
  ueMaterialSlice,
} from "../material/material-slice";
import { moveDayslots } from "./move-dayslots";
import {
  calendar_days,
  prepareCalendarDataFromTimeslots,
  setUpSequenceItem,
} from "../../common/components/sequences/Utils.js";
import { uniqueId } from "../../common/components/sequence_calendar/Utils.js";

const initState = {
  _id: false,
  dayslotid: "",
  dayslotdate: "",
  dayslot_title: "",
  dayslot_tab: "notes-unit",
  dayslot_note: "",
  active_sequence_item: {},
  sequenceCluster: {},
  slotdays: [],
  timeslots: [],
  active_timeslot: [],
  sortpaneid: uniqueId(),
  addNewPane: false,
  slot_duration: 45,
  _internal_state: false,
  _active_sequence: {},
  _sequence_nr: "",
  dayslot_homework: "",
  dayslot_teachtools: "",
  dayslot_didakt: "",
  dayslot_lehrplanbezug: "",
  dayslot_hours: "",
  active_sequence_didakt: "",
  editor_idx: uniqueId(),
  dayObjId: null,
  materialActiveTabType: null,
  materialModel: sequenceMaterialSlice.getInitialState(),
  ueMaterialModel: sequenceMaterialSlice.getInitialState(),
  show404: false,
  note: "",
};

export const sequenceMaterialSelector = (state) =>
  state.sequences.materialModel;
export const ueMaterialSelector = (state) => state.sequences.ueMaterialModel;

export function sequences(state = initState, action) {
  // delegate material actions to specific reducers
  if (action.type.startsWith(`${sequenceMaterialSlice.name}/`)) {
    const newMaterialModel = sequenceMaterialSlice.reducer(
      state.materialModel,
      action,
    );
    return { ...state, materialModel: newMaterialModel };
  }

  if (action.type.startsWith(`${ueMaterialSlice.name}/`)) {
    const newUeMaterialModel = ueMaterialSlice.reducer(
      state.ueMaterialModel,
      action,
    );
    return { ...state, ueMaterialModel: newUeMaterialModel };
  }

  switch (action.type) {
    case ACTIONS.RESET_INTERNAL:
      return { ...state, _internal_state: false };

    case ACTIONS.MATERIAL_ACTIVE_TAB:
      return { ...state, materialActiveTabType: action.value };

    case ACTIONS.SET_NEX_SUBJECT:
      return {
        ...state,
        _internal_state: true,
        _active_sequence: action.item,
        _sequence_nr: action.sequence_nr,
      };

    case ACTIONS.UPDATE_TIMESLOTS: {
      const slot = action.sequences.timeslots.find(
        (item) => item.id === state.dayslotid,
      );
      return {
        ...state,
        timeslots: action.sequences.timeslots,
        dayObjId: slot ? slot.ueId : state.dayObjId,
      };
    }

    case ACTIONS.ACTIVE_SEQUENCE_ERROR_PATCH: {
      return {
        ...state,
        show404: action.show404,
      };
    }

    /**
     * set the active sequence_item with metadata from calendar
     */
    case ACTIONS.ACTIVE_SEQUENCE: {
      /**
       * wenn timeslots leer sind, also noch keiner angelegt ist, timeslots für die komplette zeitleiste anlegen
       * key 0 = erstes element zeitleiste im array.
       *
       * beim existierenden timeslosts liste prüfen ob diese noch passt und entsprechend anpassen.
       *
       * */

      const sequenceItem = setUpSequenceItem(
        action.data.subject,
        action.data.sequenceItem,
        action.school,
        action.currentAbSettings,
        action.isAbWeekActive,
        action.lessonDuration,
      );

      const seq_didakt = sequenceItem.data.didakt
        ? sequenceItem.data.didakt
        : "";

      const tabValue = sequenceItem.data.tab_created
        ? "notes-unit"
        : "articulation";

      let start_day = false;

      const timeslots =
        action.data.sequences && action.data.sequences.timeslots
          ? [...action.data.sequences.timeslots]
          : [];

      const ueIndex =
        action.ueIdx ||
        (action.ueId
          ? timeslots.findIndex((list) => list.ueId === action.ueId)
          : action.activeInitDate);

      const calendarData = calendar_days(
        sequenceItem.data.calc_date,
        sequenceItem.data.calc_end_date,
        sequenceItem.scooldays,
        action.school.holidays,
        action.school.calendarMap,
        action.isAbWeekActive,
        action.currentAbSettings,
      );

      const calDays = prepareCalendarDataFromTimeslots(calendarData, timeslots);

      calDays.forEach((elem, i) => {
        if (timeslots[i]) {
          if (ueIndex && Number(ueIndex) === i) {
            start_day = elem;
          }
          timeslots[i].id = elem.day_id;
          timeslots[i].date = elem.date;
          elem.title = timeslots[i].title;
        } else {
          elem.title = "";
          timeslots.push({
            objId: false,
            id: elem.day_id,
            data: [],
            title: "",
            tab: tabValue,
            note: "",
            teach_tools: "",
            homework: "",
            idx: i,
            date: elem.date,
            didakt: "",
            lehrplanbezug: [],
          });
        }
        // holiday
        if (!elem.holiday && !start_day) {
          start_day = elem;
        }
      });

      const foundIndex = timeslots.findIndex(
        (el) => String(el.id) === String(start_day.day_id),
      );

      // if timeslots exists, use it otherwise create new one
      const daySlotProps =
        foundIndex !== -1
          ? {
              active_timeslot: timeslots[foundIndex].data,
              dayslot_title: timeslots[foundIndex].title,
              dayslot_tab: timeslots[foundIndex].tab || tabValue,
              dayslot_note: timeslots[foundIndex].note,
              dayslot_teachtools: timeslots[foundIndex].teach_tools,
              dayslot_homework: timeslots[foundIndex].homework,
              dayslot_didakt: timeslots[foundIndex].didakt || "",
              dayslot_lehrplanbezug: timeslots[foundIndex].lehrplanbezug || "",
            }
          : {
              active_timeslot: [],
              dayslot_title: "",
              dayslot_tab: tabValue,
              dayslot_note: "",
              dayslot_teachtools: "",
              dayslot_homework: "",
              dayslot_didakt: "",
              dayslot_lehrplanbezug: [],
            };

      return {
        ...state,
        dayObjId:
          timeslots[foundIndex] && timeslots[foundIndex].ueId
            ? timeslots[foundIndex].ueId
            : false,
        dayslotid: start_day.day_id,
        active_sequence_item: sequenceItem.data,
        sequenceCluster: sequenceItem,
        timeslots,
        slotdays: calDays,
        active_slot_idx: foundIndex,
        ...daySlotProps,
        slot_duration: start_day.slot_duration,
        dayslotdate: start_day.dayslotdate,
        dayslot_hours: start_day.hours_text,
        active_sequence_didakt: seq_didakt,
        editor_idx: uniqueId(),
        _id:
          action.data.sequences && action.data.sequences._id
            ? action.data.sequences._id
            : false,
        show404: false,
        note: sequenceItem.data.note ?? "",
      };
    }

    case ACTIONS.UPDATE_SEQUENCE_ITEM_DATA:
      return { ...state, active_sequence_item: action.sequence_item };

    case ACTIONS.UPDATE_SEQUENCE_NOTE:
      return { ...state, note: action.note };

    /**
     * set/get the slotdays
     */
    case ACTIONS.SET_SLOTDAYS:
      return { ...state, slotdays: action.slotdays };

    case ACTIONS.CHANGE_DAYSLOT_TAB: {
      const foundIndex = state.timeslots.findIndex(
        (el) => String(el.id) === String(action.id),
      );

      // find dayslot with dayslotid
      // set tab
      const calendar = state.slotdays.map((c) => {
        if (String(c.day_id) === String(action.id)) {
          return { ...c, tab: action.tab };
        }
        return c;
      });

      if (foundIndex !== -1) {
        const new_state = deepSet(
          [foundIndex, "tab"],
          action.tab,
          state.timeslots,
        );

        const { tab } = action;
        return {
          ...state,
          slotdays: calendar,
          timeslots: new_state,
          dayslot_tab: tab,
        };
      }
      // add an empty new one
      const obj = {
        id: action.id,
        data: [],
        tab: action.tab,
        title: "",
        teach_tools: "",
        homework: "",
        didakt: "",
        lehrplanbezug: [],
      };
      const new_state = {
        ...state,
        slotdays: calendar,
        dayslot_tab: action.tab,
        timeslots: [...state.timeslots, obj],
      };
      return new_state;
    }

    case ACTIONS.CHANGE_DAYSLOT_TITLE: {
      const foundIndex = state.timeslots.findIndex(
        (el) => String(el.id) === String(action.id),
      );

      // find dayslot with dayslotid
      const calendar = state.slotdays.map((c) => {
        if (String(c.day_id) === String(action.id)) {
          return {
            ...c,
            title: action.title,
          };
        }
        return c;
      });

      if (foundIndex !== -1) {
        const new_state = deepSet(
          [foundIndex, "title"],
          action.title,
          state.timeslots,
        );

        const { title } = action;

        return {
          ...state,
          slotdays: calendar,
          timeslots: new_state,
          dayslot_title: title,
        };
      }
      // add an empty new one
      const obj = {
        id: action.id,
        data: [],
        title: action.title,
        teach_tools: "",
        homework: "",
        tab: "",
        didakt: "",
        lehrplanbezug: [],
      };
      const new_state = {
        ...state,
        slotdays: calendar,
        dayslot_title: "",
        timeslots: [...state.timeslots, obj],
      };
      return new_state;
    }
    /**
     * handle dayslot notes
     */
    case ACTIONS.CHANGE_DAYSLOT_NOTE: {
      const foundIndex = state.timeslots.findIndex(
        (el) => String(el.id) === String(action.id),
      );

      // find dayslot with dayslotid
      const calendar = state.slotdays.map((c) => {
        if (String(c.day_id) === String(action.id)) {
          return {
            ...c,
            note: action.note,
          };
        }
        return c;
      });

      if (foundIndex !== -1) {
        const new_state = deepSet(
          [foundIndex, "note"],
          action.note,
          state.timeslots,
        );

        const { note } = action;
        return {
          ...state,
          slotdays: calendar,
          timeslots: new_state,
          dayslot_note: note,
        };
      }
      // add an empty new one
      const obj = {
        id: action.id,
        data: [],
        title: action.title,
        teach_tools: "",
        homework: "",
        tab: "",
        didakt: "",
        lehrplanbezug: [],
      };
      const new_state = {
        ...state,
        slotdays: calendar,
        dayslot_title: "",
        timeslots: [...state.timeslots, obj],
      };
      return new_state;
    }
    case ACTIONS.CHANGE_TEACH_TOOLS: {
      const foundIndex = state.timeslots.findIndex(
        (el) => String(el.id) === String(state.dayslotid),
      );

      if (foundIndex !== -1) {
        const new_state = deepSet(
          [foundIndex, "teach_tools"],
          action.text,
          state.timeslots,
        );

        const { text } = action;

        return { ...state, timeslots: new_state, dayslot_teachtools: text };
      }
      return state;
    }
    case ACTIONS.CHANGE_HOMEWORK: {
      const foundIndex = state.timeslots.findIndex(
        (el) => String(el.id) === String(state.dayslotid),
      );
      if (foundIndex !== -1) {
        const new_state = deepSet(
          [foundIndex, "homework"],
          action.text,
          state.timeslots,
        );

        const { text } = action;

        return { ...state, timeslots: new_state, dayslot_homework: text };
      }
      return state;
    }
    case ACTIONS.CHANGE_SEQUENCE_ITEM_DIDAKT: {
      const new_state = { ...state, active_sequence_didakt: action.text };
      return new_state;
    }
    case ACTIONS.CHANGE_DIKTA_TEXT: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(state.dayslotid),
      );
      if (foundIndex !== -1) {
        const new_state = deepSet(
          [foundIndex, "didakt"],
          action.text,
          state.timeslots,
        );

        const { text } = action;
        return { ...state, timeslots: new_state, dayslot_didakt: text };
      }
      return state;
    }

    case ACTIONS.CHANGE_LEHRPLANBEZUG: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(state.dayslotid),
      );
      if (foundIndex !== -1) {
        let new_state = deepSet(
          [foundIndex, "lehrplanbezug"],
          action.tags,
          state.timeslots,
        );

        const text = action.tags;
        new_state = {
          ...state,
          timeslots: new_state,
          dayslot_lehrplanbezug: text,
        };

        return new_state;
      }
      return state;
    }
    /**
     * set dayslot id of active timelost
     */
    case ACTIONS.SET_DAYSLOT_ID: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(action.id),
      );

      const tab_settings = action.created_tab_new
        ? "notes-unit"
        : "articulation";

      if (foundIndex === -1) {
        const obj = {
          id: action.id,
          data: [],
          title: "",
          tab: tab_settings,
          note: "",
          teach_tools: "",
          homework: "",
          lehrplanbezug: [],
          didakt: "",
        };

        return {
          ...state,
          dayslotid: action.id,
          dayslot_title: "",
          dayslot_tab: tab_settings,
          dayslot_note: "",
          timeslots: [...state.timeslots, obj],
          sortpaneid: uniqueId(),
          active_timeslot: obj.data,
          slot_duration: action.slot_duration,
          dayslotdate: action.dayslotdate,
          dayslot_teachtools: "",
          dayslot_homework: "",
          dayslot_lehrplanbezug: [],
          dayslot_hours: action.hours_text,
          dayslot_didakt: "",
          active_slot_idx: foundIndex,
        };
      }

      const timeslot = state.timeslots[foundIndex];

      return {
        ...state,
        addNewPane: false,
        sortpaneid: uniqueId(),
        dayslotid: action.id,
        active_slot_idx: foundIndex,
        dayObjId: timeslot.ueId,
        active_timeslot: timeslot.data,
        slot_duration: action.slot_duration,
        dayslotdate: action.dayslotdate,
        dayslot_title: timeslot.title,
        dayslot_note: timeslot.note,
        dayslot_tab: timeslot.tab || tab_settings,
        dayslot_teachtools: timeslot.teach_tools,
        dayslot_homework: timeslot.homework,
        dayslot_lehrplanbezug: timeslot.lehrplanbezug,
        dayslot_didakt: timeslot.didakt,
        dayslot_hours: action.hours_text,
      };
    }
    /**
     * set the moved dayslot elem in the new state
     */
    case ACTIONS.MOVE_DAYSLOT_DATA: {
      const { timeslots, slotdays } = state;

      const activeIndexBefore = state.active_slot_idx;
      const activeItem = state.timeslots[activeIndexBefore];

      // move slots
      const update = moveDayslots(action.data, timeslots, slotdays);

      const activeIndexAfter = update.timeslots.findIndex(
        (item) => activeItem.ueId === item.ueId,
      );

      const newActiveItem = update.timeslots[activeIndexAfter];
      const newSlotDay = update.slotdays[activeIndexAfter];

      return {
        ...state,
        addNewPane: false,
        sortpaneid: uniqueId(),
        timeslots: update.timeslots,
        slotdays: update.slotdays,
        dayslotid: newSlotDay.day_id,
        active_slot_idx: activeIndexAfter,
        dayObjId: newActiveItem.ueId,
        slot_duration: newSlotDay.slot_duration,
        dayslot_hours: newSlotDay.hours_text,
        dayslotdate: newSlotDay.dayslotdate,
        active_timeslot: newActiveItem.data,
        dayslot_title: newActiveItem.title,
        dayslot_tab: newActiveItem.tab,
        dayslot_note: newActiveItem.note,
        dayslot_teachtools: newActiveItem.teach_tools,
        dayslot_homework: newActiveItem.homework,
        dayslot_didakt: newActiveItem.didakt,
        dayslot_lehrplanbezug: newActiveItem.lehrplanbezug,
      };
    }
    case ACTIONS.RESORT_PANES: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(state.dayslotid),
      );
      if (foundIndex !== -1) {
        const new_timeslots = deepSet(
          [foundIndex, "data"],
          action.panes,
          state.timeslots,
        );
        const new_state = {
          ...state,
          timeslots: new_timeslots,
          sortpaneid: uniqueId(),
          active_timeslot: action.panes,
        };
        return new_state;
      }
      return state;
    }
    /**
     * add a pane with custom duration to timeslot
     */
    case ACTIONS.ADD_PANE_TO_SLOT: {
      // index slot
      const foundIndex = state.timeslots.findIndex(
        (el) => el.id === state.dayslotid,
      );

      // neues objekt
      const id = uniqueId();
      const obj = {
        id,
        duration: action.duration,
        title: "",
      };

      // find dayslot with dayslotid
      const calendar = state.slotdays;

      let newTimeslots;
      let activeSlots;
      if (foundIndex === -1) {
        const newSlot = { id: state.dayslotid, title: "", data: [obj] };
        newTimeslots = Array.isArray(state.timeslots)
          ? [...state.timeslots, newSlot]
          : [newSlot];
        activeSlots = [obj];
      } else {
        const arr = state.timeslots[foundIndex].data;
        const updatedArr = [...arr, obj];
        newTimeslots = deepSet(
          [foundIndex, "data"],
          updatedArr,
          state.timeslots,
        );

        activeSlots = updatedArr;
      }

      const new_state = {
        ...state,
        timeslots: newTimeslots,
        sortpaneid: uniqueId(),
        addNewPane: true,
        active_timeslot: activeSlots,
        slotdays: calendar,
      };
      return new_state;
    }
    /**
     * action for changing text inside pane
     */
    case ACTIONS.CHANGE_PANE_TEXT: {
      const foundIndex = state.timeslots.findIndex(
        (el) => el.id === state.dayslotid,
      );
      if (foundIndex !== -1) {
        const new_timeslots = deepSet(
          [foundIndex, "data", action.id, action.change_type],
          action.text,
          state.timeslots,
        );

        const active_slots = new_timeslots[foundIndex]?.data;

        const new_state = {
          ...state,
          timeslots: new_timeslots,
          addNewPane: false,
          active_timeslot: active_slots,
        };

        return new_state;
      }
      return state;
    }
    /**
     * change materal list
     */
    case ACTIONS.CHANGE_MATERIAL_LIST: {
      const foundIndex = state.timeslots.findIndex(
        (el) => el.id === state.dayslotid,
      );
      if (foundIndex !== -1) {
        const new_timeslots = deepSet(
          [foundIndex, "data", action.id, "material"],
          action.tags,
          state.timeslots,
        );

        const active_slots = new_timeslots[foundIndex].data;

        const new_state = {
          ...state,
          timeslots: new_timeslots,
          addNewPane: false,
          active_timeslot: active_slots,
        };
        return new_state;
      }
      return state;
    }
    /**
     * action for pane resize
     */
    case ACTIONS.PANE_RESIZE: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(state.dayslotid),
      );
      if (foundIndex !== -1) {
        const new_timeslots = deepSet(
          [foundIndex, "data", action.id, "duration"],
          action.duration,
          state.timeslots,
        );

        const active_slots = new_timeslots[foundIndex].data;

        const new_state = {
          ...state,
          timeslots: new_timeslots,
          addNewPane: false,
          active_timeslot: active_slots,
        };
        return new_state;
      }
      return state;
    }
    case ACTIONS.DELETE_PANES: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(state.dayslotid),
      );
      if (foundIndex !== -1) {
        const new_timeslots = deepSet(
          [foundIndex, "data"],
          [],
          state.timeslots,
        );
        const active_timeslot = [];
        const new_state = {
          ...state,
          timeslots: new_timeslots,
          active_timeslot,
        };
        return new_state;
      }
      return state;
    }
    case ACTIONS.DELETE_PANE: {
      const foundIndex = state.timeslots.findIndex(
        (el) => Number(el.id) === Number(state.dayslotid),
      );
      if (foundIndex !== -1) {
        const new_timeslots = state.timeslots[foundIndex].data.filter(
          (item, index) => action.id !== index,
        );

        const timeslots = deepSet(
          [foundIndex, "data"],
          new_timeslots,
          state.timeslots,
        );

        const new_state = {
          ...state,
          timeslots,
          active_timeslot: new_timeslots,
        };
        return new_state;
      }
      return state;
    }

    default:
      return state;
  }
}
