import { createSelector } from "@reduxjs/toolkit";
import * as CONST from "../../common/constants/Components";
import { deepSet } from "../../common/utils/assortments";
import * as ACTIONS from "./actions";
import { findDatesInMap } from "../../common/components/sequence_calendar/Utils";
import {
  selectLessonDuration,
  selectABWeekSettings,
} from "../settings/settings-slice";
import { selectSchoolWithHolidays } from "../school/reducer";

const defaultState = {
  classlists: [],
  sequences: { sequence_items: [] },
  sequences_list: [],
};

export function sequence_items(state = defaultState, action) {
  switch (action.type) {
    case ACTIONS.GET_SEQUENCE_ITEMS:
      return action.payload;

    case ACTIONS.SAVE_SEQUENCE_ITEMS:
      return {
        ...state,
        sequences: {
          ...state.sequences,
          sequence_items: action.sequence_items,
        },
      };

    case ACTIONS.STAMP_ITEM: {
      const cluster_index = Number(action.cluster);
      const newSequenceItems = deepSet(
        [cluster_index, "lastupdate"],
        String(Date.now()),
        state.sequences.sequence_items,
      );
      return {
        ...state,
        sequences: {
          ...state.sequences,
          sequence_items: newSequenceItems,
        },
      };
    }

    default:
      return state;
  }
}

// SELECTORS

/**
 * handle and prepare initial state data
 * @param data
 * @return {{type: string, sequence_items: Array, data: *, message: *}}
 */
function prepareSequenceItems(
  data,
  school,
  ab_weeks,
  ab_settings = false,
  lessonDuration,
) {
  const class_subject_list = [];
  const subjects = data.classlists;

  subjects.forEach((elem) => {
    elem.subjects.forEach((subs) => {
      const tmp_item = { ...subs };
      tmp_item.classroom_id = elem.classId;
      tmp_item.schoolyear = elem.schoolyear;
      tmp_item.classroom_title = elem.title;
      tmp_item.subjectlist_id = subs._id;

      if (subs.schooldays.length > 0) {
        const new_school_list = [];
        subs.schooldays.forEach((schoolday) => {
          if (ab_settings && schoolday.week_type) {
            const elem_exists_idx = new_school_list.findIndex(
              (list) =>
                list.day === schoolday.day &&
                list.week_type === schoolday.week_type,
            );
            if (elem_exists_idx === -1) {
              // not exists push it
              new_school_list.push({
                count: 1,
                day: schoolday.day,
                day_name: schoolday.day_name,
                week_type: schoolday.week_type,
              });
            } else {
              // element exists, increment hours
              new_school_list[elem_exists_idx].count += 1;
            }
          } else {
            const elem_exists_idx = new_school_list.findIndex(
              (list) => list.day === schoolday.day,
            );
            if (elem_exists_idx === -1) {
              // erstellen der Stunden usw. ohne AB Wochen
              new_school_list.push({
                count: 1,
                day: schoolday.day,
                day_name: schoolday.day_name,
                week_type: schoolday.week_type,
              });
            } else {
              new_school_list[elem_exists_idx].count += 1;
            }
          }
        });

        const result = new_school_list;

        result.forEach((sd) => {
          sd.hour = sd.count * lessonDuration;
          sd.day = Number(sd.day) + 1;
        });

        tmp_item.scooldays = result;
      } else {
        tmp_item.scooldays = [];
      }
      class_subject_list.push(tmp_item);
    });
  });

  let items = [];
  if (data.sequences) {
    // push data elements from exists sequence items to subject list.
    // subject list comes from settings an is newer in some cases.
    items = data.sequences.sequence_items;
    const new_list = class_subject_list;
    new_list.forEach((elem) => {
      items.forEach((item) => {
        const mappedDataList = [];
        if (
          item._id === elem._id &&
          Number(item.classroom_id) === Number(elem.classroom_id)
        ) {
          if (item.data) {
            item.data.forEach((ed) => {
              const map_res = findDatesInMap(
                school.calendarMap,
                ed.pos,
                ed.weeks,
                school.holidays,
                elem.scooldays,
                ab_weeks,
                ab_settings,
                lessonDuration,
              );

              mappedDataList.push({
                ...ed,
                calc_date: map_res.calc_date,
                calc_end_date: map_res.calc_end_date,
                date: map_res.date,
                daterange: map_res.date_range,
                label_weeks: map_res.label_weeks,
                label_hours: map_res.label_scoolhours,
              });
            });
          }

          elem.data = mappedDataList;
          elem.height = item.height;
          elem.diktat = item.diktat ? item.diktat : "";
        }
        if (!elem.data) {
          elem.data = [];
        }
      });
    });

    // check of origin subjects are same length, if not push an empty data array.
    if (new_list.length > items.length) {
      new_list.forEach((elem) => {
        if (!elem.data) {
          elem.data = [];
        }
      });
    }
    items = new_list;
  } else {
    // nothing exists - set new
    items = class_subject_list;
    items.forEach((elem) => {
      elem.data = [];
      elem.height = CONST.CALENDAR_ITEMS_ROW_HEIGHT;
      elem.diktat = "";
    });
  }
  return items;
}

export const selectSequenceItems = createSelector(
  [
    (state) => state.sequence_items,
    selectSchoolWithHolidays,
    selectABWeekSettings,
    selectLessonDuration,
  ],
  (seqItems, school, abWeekSettings, lessonDuration) =>
    prepareSequenceItems(
      seqItems,
      school,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
      lessonDuration,
    ),
);
